.hintWrapper {

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;

	.icon {

		width: 45px;
		height: 45px;

		svg {
			width: 45px;
			height: 45px;
			fill: var(--color-medium-neutral);
		}
	}

}
