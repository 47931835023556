.react-datepicker {
	font-family: var(--font-family);
}

.react-datepicker-wrapper {
	display: block;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
	border-color: var(--color-turquoise);
}

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	border-width: 2px 2px 0 0;
}

.react-datepicker__navigation:hover *::before {
	border-color: var(--color-dark-turquoise);
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
	border-top-color: var(--primary-color);
}

.react-datepicker {
	border: none;
	box-shadow: var(--box-shadow-default);
}

.react-datepicker__header {

	background-color: var(--color-white);
	border-bottom: none;

	* {
		font-weight: var(--typescale-default-weight) !important;
	}

}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	font: var(--typescale-body-large-font);
	color: var(--color-darker-neutral);
}

.react-datepicker__day-name {
	font: var(--typescale-body-font);
	color: var(--color-dark-neutral);
	width: 2.5rem !important;
	height: 2.5rem !important;
	line-height: 2.35rem !important;
}

.react-datepicker-popper {
	z-index: 5;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
	width: 0;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
	border-bottom-color: var(--color-white);
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
	margin-top: -7px;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
	border: 1px solid transparent;
	outline: none !important;
	font: var(--typescale-body-font);
	color: var(--color-darker-neutral);
}

.react-datepicker__day--outside-month {
	color: var(--color-light-neutral);
}

.react-datepicker__day {
	border-radius: 50% !important;
	width: 2.5rem !important;
	height: 2.5rem !important;
	line-height: 2.35rem !important;
}

.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
	border-radius: 1rem !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	background-color: var(--color-turquoise) !important;
	color: var(--color-white) !important;
	border-color: var(--color-turquoise) !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
	background-color: var(--color-white);
	border-color: var(--color-turquoise);
	color: var(--color-darker-neutral);
}

.react-datepicker__navigation {
	outline: none !important;
}

.react-datepicker__year-read-view--selected-year {
	font-weight: var(--typescale-strong-weight);
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
	background-color: #ffffff;
	border: none;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
	background-color: var(--color-white);
	border-color: var(--color-turquoise);
	color: var(--color-darker-neutral);
}

.react-datepicker__year-option--selected_year {
	background-color: var(--color-turquoise);
	color: var(--color-white);
	border-color: var(--color-turquoise);
}

.react-datepicker__year-option--selected {
	display: none;
}

.react-datepicker__month-text {
	font-size: 0.7rem;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
	width: 5rem;
	margin: 4px 2px;
	padding: 2px 0;
}

.react-datepicker__navigation--years-previous {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(../../img/icons/chevron-down.svg);
}

.react-datepicker__navigation--years-upcoming {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(../../img/icons/chevron-up.svg);
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
	color: var(--color-light-neutral);
}
