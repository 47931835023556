@import 'src/assets/styles/mixins/_breakpoints.scss';

$footerHeight: 50px;

.wrapper {

	width: 100vw;
	padding-top: 10px;
	padding-bottom: 10px;
	height: auto;
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: var(--color-white);
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 1;

	@include bp-min(md) {
		height: $footerHeight;
		padding-top: 0;
		padding-bottom: 0;
	}

}

.inner {
	width: 100%;
}

.textSpacer {

	display: none;
	width: 15px;

	@include bp-min(md) {
		display: inline-block;
	}

}

.title {

	margin-bottom: 10px;

	@include bp-min(md) {
		margin-bottom: 0;
	}

}

.link {

	display: block;
	margin-bottom: 2px;

	@include bp-min(md) {
		display: inline;
		margin-bottom: 0;
	}

}
