@import 'src/assets/styles/mixins/_breakpoints.scss';

.inputWrapper {

	display: flex;
	flex-direction: row;
	gap: 0.5rem;

	&:hover {

		.checkbox {

			&:not(:disabled):not(.error) {

				& ~ .check {

					border-color: var(--color-darker-neutral);

					&.checked {
						background-color: var(--color-dark-turquoise);
						border-color: var(--color-dark-turquoise);
					}

				}

			}

		}

	}

}

.checkbox {

	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

	&:focus,
	&:focus-visible {

		& ~ .check {
			box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
		}

	}

	&.error {

		& ~ .check {

			&:not(.checked) {
				border-color: var(--color-dark-red);
			}

		}

	}

	&:disabled {

		& ~ .check {
			background-color: var(--color-lighter-neutral);
			border-color: var(--color-light-neutral);
			cursor: not-allowed;
		}

	}

}

.label {

	order: 2;
	cursor: pointer;

	&.labelDisabled {

		opacity: 0.5;
		cursor: not-allowed;

		& > * {
			cursor: not-allowed;
		}

	}

}

.check {

	position: relative;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: var(--border-radius-small);
	border: 1px solid var(--color-medium-neutral);
	background-color: var(--color-white);
	cursor: pointer;
	order: 1;
	flex-shrink: 0;

	svg {
		display: none;
		width: 0.8rem;
		height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		fill: var(--color-white);
	}

	&.checked {

		background-color: var(--color-turquoise);
		border-color: var(--color-turquoise);

		svg {
			display: block;
		}

	}

}
