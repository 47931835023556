:root {
	// Form
	--input-error-color: var(--color-medium-red);
	--input-error-message-color: var(--input-error-color);

	--input-label-font-weight: 400;

	--input-label-font-size-desktop: 1rem;
	--input-label-font-size-tablet: var(--input-label-font-size-desktop);
	--input-label-font-size-mobile: var(--input-label-font-size-tablet);

	--input-label-font-size-s-desktop: 10px;
	--input-label-font-size-s-tablet: var(--input-label-font-size-s-desktop);
	--input-label-font-size-s-mobile: var(--input-label-font-size-s-tablet);

	--input-label-font-size-desktop-active: 0.75rem;
	--input-label-font-size-tablet-active: var(--input-label-font-size-desktop-active);
	--input-label-font-size-mobile-active: 12px;

	--input-label-font-size-s-desktop-active: var(--input-label-font-size-s-desktop);
	--input-label-font-size-s-tablet-active: var(--input-label-font-size-s-desktop-active);
	--input-label-font-size-s-mobile-active: var(--input-label-font-size-s-tablet-active);

	--input-label-position-top-desktop: 12px;
	--input-label-position-top-tablet: var(--input-label-position-top-desktop);
	--input-label-position-top-mobile: var(--input-label-position-top-tablet);

	--input-label-position-top-s-desktop: 5px;
	--input-label-position-top-s-tablet: var(--input-label-position-top-s-desktop);
	--input-label-position-top-s-mobile: var(--input-label-position-top-s-tablet);

	--input-label-position-top-desktop-active: 4px;
	--input-label-position-top-tablet-active: var(--input-label-position-top-desktop-active);
	--input-label-position-top-mobile-active: var(--input-label-position-top-tablet-active);

	--input-label-position-top-s-desktop-active: var(--input-label-position-top-s-desktop);
	--input-label-position-top-s-tablet-active: var(--input-label-position-top-s-desktop-active);
	--input-label-position-top-s-mobile-active: var(--input-label-position-top-s-tablet-active);

	--input-label-position-left-desktop: 16px;
	--input-label-position-left-tablet: var(--input-label-position-left-desktop);
	--input-label-position-left-mobile: var(--input-label-position-left-tablet);

	--input-label-position-left-s-desktop: 10px;
	--input-label-position-left-s-tablet: var(--input-label-position-left-s-desktop);
	--input-label-position-left-s-mobile: var(--input-label-position-left-s-tablet);

	--input-label-color: var(--color-dark-neutral);
	--input-label-color-active: var(--color-turquoise);
	--input-label-color-disabled: var(--color-light-neutral);
	--input-label-color-error: var(--input-error-message-color);

	--input-message-color: var(--color-dark-red);

	--input-tooltip-top: 13px;
	--input-tooltip-right: 15px;

	--input-suffix-font-size: var(--input-font-size-desktop);
	--input-suffix-font-weight: 400;
	--input-suffix-right: 15px;
	--input-suffix-right-tooltip: 50px;

	--input-suffix-top-desktop: 16px;
	--input-suffix-top-tablet: var(--input-suffix-top-desktop);
	--input-suffix-top-mobile: var(--input-suffix-top-tablet);

	// Input
	--input-height-desktop: 48px;
	--input-height-tablet: var(--input-height-desktop);
	--input-height-mobile: var(--input-height-tablet);

	--input-height-s-desktop: 44px;
	--input-height-s-tablet: var(--input-height-s-desktop);
	--input-height-s-mobile: var(--input-height-s-tablet);

	--input-padding-desktop: 20px 16px 4px 16px;
	--input-padding-tablet: var(--input-padding-desktop);
	--input-padding-mobile: var(--input-padding-tablet);

	--input-padding-s-desktop: 8px 8px 0 8px;
	--input-padding-s-tablet: var(--input-padding-s-desktop);
	--input-padding-s-mobile: var(--input-padding-s-tablet);

	--input-border-radius: 4px 4px 0 0;

	--input-font-size-desktop: 16px;
	--input-font-size-tablet: var(--input-font-size-desktop);
	--input-font-size-mobile: var(--input-font-size-tablet);

	--input-font-size-s-desktop: 14px;
	--input-font-size-s-tablet: var(--input-font-size-s-desktop);
	--input-font-size-s-mobile: var(--input-font-size-s-tablet);

	--input-font-weight: 400;

	--input-border-style: solid;

	--input-border-color: #9bc3d3;
	--input-border-color-active: var(--color-turquoise);
	--input-border-color-error: var(--input-error-message-color);

	--input-border-top-color: var(--input-border-color);
	--input-border-bottom-color: var(--color-turquoise);
	--input-border-left-color: var(--input-border-color);
	--input-border-right-color: var(--input-border-color);

	--input-border-top-color-active: var(--input-border-color-active);
	--input-border-bottom-color-active: var(--input-border-bottom-color);
	--input-border-left-color-active: var(--input-border-color-active);
	--input-border-right-color-active: var(--input-border-color-active);

	--input-border-top-color-error: var(--input-border-color);
	--input-border-bottom-color-error: var(--input-border-color-error);
	--input-border-left-color-error: var(--input-border-color);
	--input-border-right-color-error: var(--input-border-color);

	--input-border-width: 1px;
	--input-border-top-width: var(--input-border-width);
	--input-border-bottom-width: var(--input-border-width);
	--input-border-left-width: var(--input-border-width);
	--input-border-right-width: var(--input-border-width);
	--input-border-top-width-active: var(--input-border-width);
	--input-border-bottom-width-active: 2px;
	--input-border-left-width-active: var(--input-border-width);
	--input-border-right-width-active: var(--input-border-width);

	--input-color: var(--color-darker-neutral);
	--input-background-color: var(--color-white);
	--input-disabled-color: var(--color-light-neutral);
	--input-disabled-border-color: var(--color-light-neutral);
	--input-disabled-background-color: #f6f6f6;

	--input-box-shadow: none;

	// Select
	--select-arrow-top-desktop: calc(var(--input-height-desktop) / 2);
	--select-arrow-top-tablet: calc(var(--input-height-tablet) / 2);
	--select-arrow-top-mobile: calc(var(--input-height-mobile) / 2);

	--select-arrow-top-s-desktop: calc(var(--input-height-s-desktop) / 2);
	--select-arrow-top-s-tablet:  calc(var(--input-height-s-tablet) / 2);
	--select-arrow-top-s-mobile:  calc(var(--input-height-s-mobile) / 2);

	--select-arrow-right-desktop: 16px;
	--select-arrow-right-tablet: 16px;
	--select-arrow-right-mobile: 16px;

	--select-arrow-right-s-desktop: 15px;
	--select-arrow-right-s-tablet: var(--select-arrow-right-s-desktop);
	--select-arrow-right-s-mobile: var(--select-arrow-right-s-tablet);

	--select-arrow-color: var(--color-purple);

	--select-items-active-top: 0;
	--select-items-padding-bottom: 15px;
	--select-items-padding-bottom-s: 5px;

	--select-item-padding-desktop: 8px 16px;
	--select-item-padding-tablet: 8px 16px;
	--select-item-padding-mobile: 8px 16px;

	--select-item-padding-s-desktop: 8px;
	--select-item-padding-s-tablet: var(--select-item-padding-s-desktop);
	--select-item-padding-s-mobile: var(--select-item-padding-s-tablet);

	--select-item-color: var(--input-color);

	--select-item-hover-background-color: var(--color-white);
	--select-item-hover-color: var(--color-purple);

	--select-item-active-background-color: var(--color-purple);
	--select-item-active-color: var(--color-white);

	--select-item-cursor-background-color: var(--color-sky);
	--select-item-cursor-color: var(--select-item-color);
	--select-item-cursor-active-background-color: var(--color-dark-purple);
	--select-item-cursor-active-color: var(--select-item-active-color);

	--select-item-height: 38px;
	--select-item-height-s: 38px;

	--select-item-divider-padding-desktop: 16px;
	--select-item-divider-padding-tablet: 16px;
	--select-item-divider-padding-mobile: 16px;

	--select-item-divider-padding-s-desktop: 8px;
	--select-item-divider-padding-s-tablet: var(--select-item-divider-padding-s-desktop);
	--select-item-divider-padding-s-mobile: var(--select-item-divider-padding-s-mobile);

	--select-item-divider-color: transparent;

	// Checkbox
	--checkbox-label-size-desktop: var(--text-font-size-s-desktop);
	--checkbox-label-size-tablet: var(--text-font-size-s-tablet);
	--checkbox-label-size-mobile: var(--text-font-size-s-mobile);

	--checkbox-label-top: 2px;

	--checkbox-box-size: 1.5rem;

	--checkbox-box-spacing: 15px;

	--checkbox-box-border-radius: 4px;
	--checkbox-box-border-width: 1px;
	--checkbox-box-border-width-checked: 1px;
	--checkbox-box-border-width-disabled: 1px;
	--checkbox-box-border-width-focus: 1px;

	--checkbox-box-background-color: var(--color-white);
	--checkbox-box-background-color-checked: var(--input-border-color-active);
	--checkbox-box-background-color-checked-focus: #77d2d7;
	--checkbox-box-background-color-focus: var(--checkbox-box-background-color);
	--checkbox-box-background-color-disabled: var(--color-lighter-neutral);

	--checkbox-box-border-color: var(--input-border-color);
	--checkbox-box-border-color-checked: var(--checkbox-box-background-color-checked);
	--checkbox-box-border-color-checked-focus: var(--checkbox-box-background-color-checked-focus);
	--checkbox-box-border-color-focus: var(--checkbox-box-background-color-checked);
	--checkbox-box-border-color-disabled: var(--input-border-color-disabled);

	--checkbox-box-checkmark-color-checked: var(--color-white);
	--checkbox-box-checkmark-color-disabled: var(--color-light-neutral);

	// Radio-Button
	--radio-button-label-font: var(--input-label-font);
	--radio-button-border-radius: 4px;

	--radio-button-height-m-desktop: var(--button-height-desktop);
	--radio-button-height-m-tablet: var(--button-height-tablet);
	--radio-button-height-m-mobile: var(--button-height-mobile);

	--radio-button-height-s-desktop: 35px;
	--radio-button-height-s-tablet: 35px;
	--radio-button-height-s-mobile: 35px;

	--radio-button-padding-m-desktop: 0 16px;
	--radio-button-padding-m-tablet: 0 16px;
	--radio-button-padding-m-mobile: 0 16px;

	--radio-button-padding-s-desktop: 0 12px;
	--radio-button-padding-s-tablet: 0 12px;
	--radio-button-padding-s-mobile: 0 12px;

	--radio-button-size-desktop: 25px;
	--radio-button-size-tablet: 25px;
	--radio-button-size-mobile: 25px;

	--radio-button-border-width: 1px;
	--radio-button-border-width-active: 2px;
	--radio-button-color: var(--color-turquoise);

	// Radio-Button: Default-Style
	--radio-button-default-label-space: 15px;
	--radio-button-default-label-color: var(--input-label-color);

	// Radio-Button: Button-Style
	--radio-button-button-background-color: var(--color-white);
	--radio-button-button-background-color-active: var(--radio-button-button-background-color);
	--radio-button-button-background-color-disabled: var(--color-lighter-neutral);

	--radio-button-button-border-color: var(--input-border-color);
	--radio-button-button-border-color-active: var(--color-turquoise);
	--radio-button-button-border-color-disabled: var(--color-light-neutral);

	--radio-button-button-label-color: var(--input-label-color);
	--radio-button-button-label-color-active: var(--radio-button-button-border-color-active);
	--radio-button-button-label-color-disabled: var(--radio-button-button-border-color-disabled);

	// Radio-Button: Switch-Style
	--radio-button-switch-background-color: var(--color-white);
	--radio-button-switch-background-color-active: var(--color-turquoise);
	--radio-button-switch-background-color-disabled: var(--color-sky);

	--radio-button-switch-border-color: var(--input-border-color);
	--radio-button-switch-border-color-active: var(--radio-button-switch-background-color-active);
	--radio-button-switch-border-color-disabled: var(--color-light-neutral);

	--radio-button-switch-label-color: var(--input-label-color);
	--radio-button-switch-label-color-active: var(--color-white);
	--radio-button-switch-label-color-disabled: var(--radio-button-switch-border-color-disabled);

	// Radio-Box
	--radio-box-background-color: var(--color-white);
	--radio-box-border-width: 1px;
	--radio-box-border-width-active: 2px;
	--radio-box-border-color: var(--color-turquoise);
	--radio-box-border-color-active: var(--radio-box-border-color);
	--radio-box-border-radius: 4px;

	--radio-box-check-size: 40px;
	--radio-box-check-size-small: 25px;
	--radio-box-check-border-width: 1px;
	--radio-box-check-border-width-active: 2px;
	--radio-box-check-border-color: var(--color-purple);
	--radio-box-check-background-color-active: var(--radio-box-check-border-color);
	--radio-box-check-color: var(--color-white);

	--radio-box-banner-background-color: var(--color-turquoise);
	--radio-box-banner-color: var(--color-white);

	--radio-box-icon-color: var(--color-purple);
}
