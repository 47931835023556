.ripple {

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;

	span {
		display: block;
		transform: scale(0);
		border-radius: 100%;
		position: absolute;
		animation-name: ripple;
		animation-duration: 1000ms;
	}

	@keyframes ripple {
		to {
			opacity: 0;
			transform: scale(2);
		}
	}
}
