@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

	position: relative;
	padding-bottom: 50px;

	@include bp-min(md) {
		padding-bottom: 100px;
	}

}

.background {

	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	padding-bottom: 75px;
	overflow: hidden;

	@include bp-min(md) {
		padding-bottom: 60px;
	}

	.backgroundInner {
		width: 100%;
		height: 100%;
		max-width: 700px;
		position: relative;
		margin-left: auto;
		margin-right: auto;

		@include bp-min(lg) {
			max-width: 950px;
		}

		@include bp-min(xl) {
			max-width: 1100px;
		}

		@include bp-min(xxl) {
			max-width: 1300px;
		}

	}

	svg {
		width: 700px;
		height: auto;
		position: absolute;
		bottom: 0;
		right: 0;

		@include bp-min(md) {
			width: 100%;
		}
	}

}
