@import 'src/assets/styles/mixins/_breakpoints.scss';
@import 'src/assets/styles/mixins/_color.scss';

.exitWindowPopup {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999999999;
	@include overlay-background;
	width: 100vw;
	height: 100vh;
	opacity: 0.00000000001;
	pointer-events: none;
	transition: opacity 0.15s;
}

.exitWindowPopupActive {
	opacity: 1;
	pointer-events: all;
}

.exitWindowPopupCloseBg {
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	z-index: 1;
}

.exitWindowPopupInner {

	width: 100%;
	max-width: calc(100vw - 30px);
	padding: 20px;
	background-color: var(--color-white);
	border-radius: 4px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;

	@include bp-min(sm) {
		max-width: 680px;
		padding: 40px;
	}

}

.icon {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	position: relative;
	background-color: var(--color-sky);
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;

	svg {
		width: 70%;
		height: 70%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		fill: var(--color-darker-sky);
	}

}

.headline {
	font-size: 40px;
	font-weight: 400;
	line-height: 1.2;
	text-align: center;
	margin-top: 0;
	margin-bottom: 20px;
}

.subhead {
	font-size: 24px;
	font-weight: 400;
	line-height: 1.2;
	text-align: center;
	margin-top: 0;
	margin-bottom: 16px;
}

.buttonRow {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include bp-min(sm) {
		flex-direction: row;
	}

	button {

		margin-bottom: 0 !important;

		&:not(:last-child) {
			margin-right: 120px;
			margin-bottom: 10px !important;
		}

	}

}

.closeButton {

	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	width: var(--icon-size-m);
	height: var(--icon-size-m);
	outline: none;
	border-radius: var(--border-radius-small);

	&:focus,
	&:focus-visible {
		box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
	}

	@include bp-min(sm) {
		top: 40px;
		right: 40px;
	}

}

.exitWindowPopupTrigger {

	width: 100vw;
	height: 20px;
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	z-index: 9999999998;

	@include bp-min(sm) {
		display: block;
	}

}
