@import 'src/assets/styles/mixins/_breakpoints.scss';

.divider {
	width: 100%;
	height: 1px;
	margin-top: 5px;
	margin-bottom: 5px;

	&:not(.noMarginTop) {
		margin-top: 20px;
	}

	&:not(.noMarginBottom) {
		margin-bottom: 20px;
	}

	&:not(.noLine) {
		background-color: var(--color-light-neutral);
	}

}

.dividerMobile {
	@include bp-min(sm) {
		display: none;
	}
}
