.box {

	position: relative;

	&.isRow {
		display: flex;
		flex-direction: row;
	}

	&.mb {
		margin-bottom: 1rem;
	}

	&.borderRadius {
		border-radius: var(--border-radius-default);
	}

	&.border {
		border-width: 1px;
		border-style: solid;
	}

	&.shadow {
		box-shadow: var(--box-shadow-default);
	}

	&.l {
		padding: 1.5rem 1.75rem;
	}

	&.m {
		padding: 1rem;
	}

	&.s {
		padding: 0.5rem 0.75rem;
	}

	.icon {
		flex-grow: 0;
		flex-shrink: 0;
		padding-right: 15px;
	}

	&.white {
		background-color: var(--color-white);
		border-color: var(--color-light-neutral);
	}

	&.secondary {
		background-color: var(--color-light-turquoise);
		border-color: var(--color-turquoise);
	}

}
