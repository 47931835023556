.message {
	display: flex;
	flex-direction: row;
	gap: 0.25rem;
}

.text {
	display: inline-block;
	font: var(--typescale-body-font);
	font-weight: var(--typescale-default-weight);
	color: var(--color-dark-neutral);
}

.icon {

	flex-shrink: 0;
	width: 1.5rem;
	height: 1.5rem;
	position: relative;

	svg {
		width: 1.25rem;
		height: 1.25rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, calc(-50% - 3px));
		fill: var(--color-dark-neutral);
	}

}

.default {

	.text {
		color: var(--color-darker-neutral);
	}

	.icon {

		svg {
			fill: var(--color-darker-neutral);
		}

	}

}

.light {

	.text {
		color: var(--color-dark-neutral);
	}

	.icon {

		svg {
			fill: var(--color-dark-neutral);
		}

	}

}

.error {

	.text {
		color: var(--color-dark-red);
	}

	.icon {

		svg {
			fill: var(--color-dark-red);
		}

	}

}

.success {

	.text {
		color: var(--color-green);
	}

	.icon {

		svg {
			fill: var(--color-green);
		}

	}

}
