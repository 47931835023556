#XS2A-Form {

	.xs2a-form-line {
		padding-left: 0;
		padding-right: 0;
	}

	h1, h2, h3, h4, h5, h6 {

		font-family: var(--font-family-headline);
		font-size: 18px;
		margin: 0 0 18px;

		@include bp-min(md) {
			font-size: 24px;
			margin-bottom: 24px;
		}

	}

	p {
		font: var(--typescale-body-large-font);
		margin: 0 0 12px;
	}

	a {
		cursor: pointer;
		transition: all var(--transition-default-time) ease-in-out;
		font-weight: var(--typescale-strong-weight);
		text-decoration: none;
		color: var(--color-purple);
	}

	select,
	input[type='text'],
	input[type='password'],
	input[type='number'] {

		position: relative;
		z-index: 1;
		display: block;
		width: 100%;
		height: 3rem;
		padding: 0.75rem;
		border-radius: var(--border-radius-default);
		background-color: var(--color-white);
		border: 1px solid var(--color-medium-neutral);
		overflow: hidden;
		outline: none;
		box-shadow: none;
		cursor: text;
		color: var(--color-darker-neutral);
		font: var(--typescale-body-large-font);
		font-weight: var(--typescale-default-weight);

		&::placeholder {
			color: var(--color-dark-neutral);
			opacity: 1;
		}

		&::-ms-input-placeholder {
			color: var(--color-dark-neutral);
		}

		&:hover {
			border-color: var(--color-darker-neutral);
		}

		&:focus,
		&:focus-visible {
			border-color: var(--color-turquoise);
			box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px var(--color-focus-input);
		}

		&:disabled {
			background-color: var(--color-lighter-neutral);
			border-color: var(--color-lighter-neutral);
			cursor: not-allowed;
		}

	}

	input[type=number] {

		-moz-appearance: textfield;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

	}

	select {

		-webkit-appearance: none;

		&:after {
			content: '';
			width: 24px;
			height: 24px;
			position: absolute;
			top: 50%;
			right: 30px;
			transform: translate(0, calc(-50% + 8px));
			z-index: 2;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0iYSIgZD0iTTEyLjAwMyAxNC41OThhLjYuNiAwIDAgMS0uNDI0LS4xNzZsLTQtNGEuNi42IDAgMCAxIC44NDktLjg0N2wzLjU3NSAzLjU3NCAzLjU3My0zLjU3MmEuNi42IDAgMCAxIC44NDguODQ4bC0zLjk5NyAzLjk5N2EuNi42IDAgMCAxLS40MjQuMTc2Ii8+CiAgICA8L2RlZnM+CiAgICA8dXNlIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIgeGxpbms6aHJlZj0iI2EiLz4KPC9zdmc+Cg==');
		}

	}

	button {

		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		box-shadow: none;
		outline: none;
		border: none;
		white-space: nowrap;
		min-height: 42px;
		background: var(--primary-color);
		margin: 0;
		height: 3rem;
		padding: 0 1.25rem;
		min-width: 8.5rem;
		border-radius: 1.5rem;
		font: var(--typescale-body-large-font);
		font-weight: var(--typescale-strong-weight);
		color: var(--color-white);
		background-color: var(--color-purple);
		margin-bottom: 1rem;

		svg {
			fill: var(--color-white);
		}

		&:hover {

			@include bp-min(sm) {
				background-color: var(--color-dark-purple);
			}

		}

		&:disabled {

			color: var(--color-light-neutral);
			background-color: var(--color-lighter-neutral);

			svg {
				fill: var(--color-light-neutral);
			}

		}

		&:focus,
		&:focus-visible {
			box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
		}

	}

	.xs2a-description {
		display: none;
	}

	.xs2a-text,
	.xs2a-select {

		label {
			color: var(--color-darker-neutral);
			font: var(--typescale-body-large-font);
			font-weight: var(--typescale-default-weight);
			margin-bottom: 0.25rem;
		}

	}

	.xs2a-checkbox {

		padding-bottom: 30px;
		position: relative;

		label {

			position: relative;
			cursor: pointer;
			margin: 0;
			padding-left: 2rem;

			font: var(--typescale-body-large-font);
			color: var(--color-darker-neutral);

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				width: var(--icon-size-m);
				height: var(--icon-size-m);
				border-radius: var(--border-radius-small);
				border: 1px solid var(--color-medium-neutral);
				background-color: var(--color-white);
				cursor: pointer;
				margin-right: 0.5rem;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				z-index: 3;
				pointer-events: none;
				width: var(--icon-size-m);
				height: var(--icon-size-m);
				border: 1px solid transparent;
				border-radius: var(--border-radius-small);
				background-size: 58%;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(../../img/svg/svg_check_white.svg);
				display: none;
			}

		}

		input[type='checkbox'] {

			opacity: 0.0000001;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			&:checked {

				& ~ label {

					&:before {
						border-color: var(--color-turquoise);
						background-color: var(--color-turquoise);
					}

					&:after {
						display: block;
					}

				}

			}

			&:focus,
			&:focus-visible {

				& ~ label {

					&:before {
						box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
					}

				}

			}

			&:disabled {

				& ~ label {

					&:before {
						border-color: var(--checkbox-box-border-color-disabled);
						border-width: var(--checkbox-box-border-width-disabled);
						background-color: var(--checkbox-box-background-color-disabled);
					}

					&:after {
						border-width: var(--checkbox-box-border-width-disabled);
						opacity: 0.5;
					}

				}

			}

		}

	}

	.xs2a-submit-line {

		border-top: 1px solid var(--layout-divider-color);
		margin-top: 10px;
		padding-top: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include bp-min(sm) {
			flex-direction: row;
		}

		&:after {
			display: none;
		}

		.xs2a-spinner {
			margin: 0;
			max-width: 100%;
			height: 42px;
			width: auto;
			margin-bottom: 10px;
			@include bp-min(sm) {
				margin-bottom: 0;
			}
		}

		.xs2a-submit {
			display: block;
			@include bp-min(sm) {
				margin-left: auto;
			}
		}

	}

	.xs2a-logo-end {

		margin-top: 15px;
		text-align: right;

		.xs2a-note {

			color: var(--color-dark-neutral);
			font-size: 12px;
			margin-bottom: 10px;

			a {
				color: var(--color-dark-neutral);
			}

			svg {
				height: 11px;
				width: auto;
				margin-right: 10px;
				fill: var(--color-dark-neutral);
			}

		}
	}

	.xs2a-flicker {
		label {
			top: -10px;
			left: 0;
		}
	}

	.xs2a-captcha {
		label {
			display: none;
			top: -20px;
			left: 0;
		}
	}

	.xs2a-error {

		padding: 1.5rem;
		border-radius: var(--border-radius-default);
		background-color: var(--color-light-red);
		font: var(--typescale-body-large-font);
		color: var(--color-darker-neutral);

		p {

			margin: 0;

			svg {
				display: none;
			}

			.sr-only {
				font-weight: var(--typescale-strong-weight);
			}

		}

	}

	.xs2a-checkbox {
		label {
			a {
				&:after {
					content: '';
					display: inline-block;
					width: 24px;
					height: 24px;
					background-image: url(../../img/icons/share-link.svg);
					background-repeat: no-repeat;
					background-size: contain;
					margin-left: 5px;
					transform: translate(0, 5px);
				}
			}
		}
	}

}
