@import 'src/assets/styles/mixins/_breakpoints.scss';

.iconGroup {
	svg {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		padding: 10px;
		margin: auto;
		width: 60%;
		height: auto;

		@include bp-min(sm) {
			width: 50%;
		}

		@include bp-min(md) {
			width: 90%;
			padding-right: 50px;
		}
	}
}

.textGroup {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.hr {
	height: 2px;
	background-color: var(--color-sky);
	border: none;
	margin-bottom: 5px;
}

.checkboxWrapper {
	padding: 5px 0 20px 0;
}

.phrase {
	@include bp-min(sm) {
		position: absolute;
		margin: 8px 0 8px 0;
		width: 50%;
	}
}
