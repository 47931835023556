@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

	gap: 0.5rem;
	width: auto;
	display: inline-flex;

	&.wrapperMaxWidth {
		display: flex;
	}

}

.items {

	display: flex;
	flex-direction: row;

	&.itemsDefault {

		gap: 0.75rem;
		flex-wrap: wrap;

		&.itemsHasFocus {
			&.noSelection {
				.inputWrapper {
					&:first-child {
						.input {
							& ~ .label {
								box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
							}
						}
					}
				}
			}
		}

	}

	&.itemsSwitch {

		gap: 0;
		flex-wrap: nowrap;
		border: 1px solid var(--color-turquoise);
		border-radius: var(--border-radius-default);
		overflow: hidden;

		&:hover {

			border-color: var(--color-dark-turquoise);

			.input:not(:disabled) {

				&:checked {

					& ~ .label {

						&.switch {
							background-color: var(--color-dark-turquoise);
						}

					}

				}

			}

		}

		&.itemsHasFocus {
			box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
		}

	}

	&.itemsTab {

		position: relative;
		gap: 0;
		flex-wrap: nowrap;
		border-radius: var(--border-radius-small);

		&.itemsMaxWidth {

			width: 100%;

			.inputWrapper {

				flex-grow: 1;

				.label {
					width: 100%;
				}

			}

		}

		&.itemsHasFocus {
			box-shadow: 0 1px 8px 0 rgba(68, 68, 68, 0.00), 0 0 0 4px #FFF, 0 0 0 6px var(--color-focus);
		}

	}

	&.itemsError {

		&.itemsSwitch {
			border-color: var(--color-dark-red);
		}

	}

	&.itemsDisabled {

		&.itemsSwitch {
			border-color: var(--color-light-neutral);
		}

	}

}

.inputWrapper {
	position: relative;
}

.label {

	position: relative;
	display: inline-flex;
	flex-direction: row;
	gap: 0.25rem;
	border: none;
	outline: none;
	box-shadow: none;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	overflow: hidden;

	height: 2.5rem;

	.text {
		position: relative;
		z-index: 3;
		display: block;
		text-align: center;
		white-space: nowrap;
		pointer-events: none;
		font: var(--typescale-body-font);
		font-weight: var(--typescale-default-weight);
	}

	.prependIcon,
	.appendIcon {

		position: relative;
		z-index: 3;
		display: block;
		width: 1.5rem;
		height: 1.5rem;
		flex-shrink: 0;
		pointer-events: none;

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}

	}

	.ripple {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		pointer-events: none;
	}

	&.default {

		padding: 0 1.25rem;
		border-radius: 1.25rem;
		border: 1px solid var(--color-medium-neutral);
		background-color: var(--color-white);

		.text {
			color: var(--color-darker-neutral);
		}

		.prependIcon,
		.appendIcon {

			svg {
				fill: var(--color-darker-neutral);
			}

		}

		&:hover {
			border-color: var(--color-darker-neutral);
		}

	}

	&.switch {

		padding: 0 1.25rem;
		background-color: var(--color-white);

		.text {
			color: var(--color-darker-neutral);
		}

		.prependIcon,
		.appendIcon {

			svg {
				fill: var(--color-darker-neutral);
			}

		}

	}

	&.tab {

		padding: 0 1.25rem;
		background-color: var(--color-white);
		border-bottom: 2px solid var(--color-medium-sky);

		.text {
			color: var(--color-darker-neutral);
			font: var(--typescale-body-large-font);
			text-align: center;
		}

		.prependIcon,
		.appendIcon {

			svg {
				fill: var(--color-darker-neutral);
			}

		}

	}

}


.input {

	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

	&.error {

		& ~ .label {

			&.default {
				border-color: var(--color-dark-red);
			}

			&.switch {}

			&.tab {
				border-color: var(--color-light-red);
			}

		}

		&:checked {

			& ~ .label {

				&.tab {
					border-color: var(--color-dark-red);
				}

			}

		}

	}

	&:disabled {

		& ~ .label {

			cursor: not-allowed;

			.ripple {
				display: none;
			}

			&.default {

				border-color: var(--color-light-neutral);
				background-color: var(--color-lighter-neutral);

				.text {
					color: var(--color-light-neutral);
				}

			}

			&.switch {

				background-color: var(--color-lighter-neutral);

				.text {
					color: var(--color-light-neutral);
				}

			}

			&.tab {

				border-color: var(--color-lighter-neutral);

				.text {
					color: var(--color-light-neutral);
				}

				.prependIcon,
				.appendIcon {

					svg {
						fill: var(--color-light-neutral);
					}

				}

			}

		}

	}


	&:checked {

		& ~ .label {

			&.default {

				border-color: var(--color-turquoise);
				background-color: var(--color-turquoise);

				.text {
					color: var(--color-white);
					font-weight: var(--typescale-strong-weight);
				}

				.prependIcon,
				.appendIcon {

					svg {
						fill: var(--color-white);
					}

				}

				&:hover {
					border-color: var(--color-dark-turquoise);
					background-color: var(--color-dark-turquoise);
				}

			}

			&.switch {

				background-color: var(--color-turquoise);

				.text {
					color: var(--color-white);
					font-weight: var(--typescale-strong-weight);
				}

				.prependIcon,
				.appendIcon {

					svg {
						fill: var(--color-white);
					}

				}

			}

			&.tab {

				border-color: var(--color-turquoise);

				.text {
					font-weight: var(--typescale-strong-weight);
				}

				.prependIcon,
				.appendIcon {

					svg {
						fill: var(--color-turquoise);
					}

				}

			}

		}

		&:disabled {

			& ~ .label {

				&.default {

					border-color: var(--color-light-neutral);
					background-color: var(--color-light-neutral);

					.text {
						color: var(--color-white);
					}

					.prependIcon,
					.appendIcon {

						svg {
							fill: var(--color-white);
						}

					}

				}

				&.switch {

					background-color: var(--color-light-neutral);

					.text {
						color: var(--color-white);
					}

					.prependIcon,
					.appendIcon {

						svg {
							fill: var(--color-white);
						}

					}

				}

				&.tab {
					border-color: var(--color-light-neutral);
				}

			}

		}

	}

	&.hasFocus:checked {

		& ~ .label {

			&.default {
				box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
			}

			&.switch {}

			&.tab {}

		}

	}

}
