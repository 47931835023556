@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	margin-bottom: 1.25rem;
}

.labelWrapper {
	display: flex;
	flex-direction: row;
	gap: 0.25rem;
}

.label {
	color: var(--color-darker-neutral);
	font: var(--typescale-body-large-font);
	font-weight: var(--typescale-default-weight);
}

.tooltip {
	width: 1.5rem;
	height: 1.5rem;
}

.inputWrapper {
	position: relative;
	width: 100%;
	height: 3rem;
}

.ripple {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	border-radius: var(--border-radius-default);
	z-index: 2;
	pointer-events: none;
}

.input {

	position: relative;
	z-index: 1;
	display: block;
	width: 100%;
	height: 3rem;
	padding: 0.75rem;
	border-radius: var(--border-radius-default);
	background-color: var(--color-white);
	border: 1px solid var(--color-medium-neutral);
	overflow: hidden;
	outline: none;
	box-shadow: none;
	cursor: text;
	color: var(--color-darker-neutral);
	font: var(--typescale-body-large-font);
	font-weight: var(--typescale-default-weight);

	&::placeholder {
		color: var(--color-dark-neutral);
		opacity: 1;
	}

	&::-ms-input-placeholder {
		color: var(--color-dark-neutral);
	}

	&:hover {
		border-color: var(--color-darker-neutral);
	}

	&.error {
		border-color: var(--color-dark-red);
	}

	&:focus,
	&:focus-visible {
		border-color: var(--color-turquoise);
		box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px var(--color-focus-input);
	}

	&:disabled {
		background-color: var(--color-lighter-neutral);
		border-color: var(--color-lighter-neutral);
		cursor: not-allowed;
	}

	&.hasSuffix {
		padding-right: 3rem;
	}

	&.pin {

		width: auto;
		overflow: hidden;
		resize: none;
		white-space: nowrap;
		text-overflow: clip;

		border-color: transparent;
		box-shadow: none;

		&[type="password"] {

			letter-spacing: 2.22rem;
			color: transparent;
			caret-color: var(--color-darker-neutral);

			& ~ .pinWrapper {

				.pinField {

					&.hasContent {

						&:before {
							content: '';
							width: 0.5rem;
							height: 0.5rem;
							border-radius: 50%;
							background-color: var(--color-darker-neutral);
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}

					}

				}

			}

		}

		&[type="text"] {
			letter-spacing: 2.08rem;
		}

		&.maxLengthReached {
			caret-color: transparent;
		}

		&:focus,
		&:focus-visible {

			& ~ .pinWrapper {

				.pinField {

					&.pinFieldActive {
						border-color: var(--color-turquoise);
						box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px var(--color-turquoise);
					}

				}

			}

		}

		&.error {

			& ~ .pinWrapper {

				.pinField {
					border-color: var(--color-dark-red);
				}

			}

		}

		&:disabled {

			background-color: transparent;

			& ~ .pinWrapper {

				.pinField {
					background-color: var(--color-lighter-neutral);
					border-color: var(--color-lighter-neutral);
					cursor: not-allowed;
				}

			}

		}

	}

}

.suffix {

	position: absolute;
	top: 50%;
	right: 1rem;
	min-width: 1.5rem;
	height: 1.5rem;
	text-align: right;
	color: var(--color-dark-neutral);
	font: var(--typescale-body-large-font);
	font-weight: var(--typescale-default-weight);
	transform: translate(0, -50%);
	z-index: 3;
	pointer-events: none;

	svg {
		display: inline-block;
		fill: var(--color-dark-neutral);
	}

}

.messageRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 0.75rem;
}

.lengthCounter {
	flex-shrink: 0;
}

.pinWrapper {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	pointer-events: none;
	display: flex;
	flex-direction: row;
}

.pinField {

	display: block;
	position: relative;
	height: 100%;
	width: 2.15rem;
	border-radius: var(--border-radius-default);
	border: 1px solid var(--color-medium-neutral);

	&.pinFieldActive {
		border-color: var(--color-darker-neutral);
	}

}

.pinSpace {
	display: block;
	height: 100%;
	width: 0.5rem;
	background-color: var(--color-white);

	&:last-child {
		flex-grow: 1;
	}

}

.pinShowWrapper {
	text-align: center;
}
