@import 'src/assets/styles/mixins/_breakpoints.scss';

@each $breakpoint in $breakpoints {

	$bp: '';
	@if $breakpoint {
		$bp: '-' + $breakpoint;
	}

	.col#{$bp}-1,
	.col#{$bp}-2,
	.col#{$bp}-3,
	.col#{$bp}-4,
	.col#{$bp}-5,
	.col#{$bp}-6,
	.col#{$bp}-7,
	.col#{$bp}-8,
	.col#{$bp}-9,
	.col#{$bp}-10,
	.col#{$bp}-11,
	.col#{$bp}-12,
	.col#{$bp},
	.col#{$bp}-auto {
		position: relative;
		width: 100%;
		min-height: 1px;
		padding-right: 5px;
		padding-left: 5px;
	}

}

@each $breakpoint in $breakpoints {

	@include bp-min($breakpoint) {

		$bp: '';
		@if $breakpoint {
			$bp: '-' + $breakpoint;
		}

		.col#{$bp} {
			-ms-flex-preferred-size: 0;
			flex-basis: 0;
			-webkit-box-flex: 1;
			-ms-flex-positive: 1;
			flex-grow: 1;
			max-width: 100%;
		}
		.col#{$bp}-auto {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 auto;
			flex: 0 0 auto;
			width: auto;
			max-width: none;
		}
		.col#{$bp}-1 {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 8.333333%;
			flex: 0 0 8.333333%;
			max-width: 8.333333%;
		}
		.col#{$bp}-2 {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 16.666667%;
			flex: 0 0 16.666667%;
			max-width: 16.666667%;
		}
		.col#{$bp}-3 {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 25%;
			flex: 0 0 25%;
			max-width: 25%;
		}
		.col#{$bp}-4 {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 33.333333%;
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
		}
		.col#{$bp}-5 {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 41.666667%;
			flex: 0 0 41.666667%;
			max-width: 41.666667%;
		}
		.col#{$bp}-6 {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%;
		}
		.col#{$bp}-7 {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 58.333333%;
			flex: 0 0 58.333333%;
			max-width: 58.333333%;
		}
		.col#{$bp}-8 {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 66.666667%;
			flex: 0 0 66.666667%;
			max-width: 66.666667%;
		}
		.col#{$bp}-9 {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 75%;
			flex: 0 0 75%;
			max-width: 75%;
		}
		.col#{$bp}-10 {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 83.333333%;
			flex: 0 0 83.333333%;
			max-width: 83.333333%;
		}
		.col#{$bp}-11 {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 91.666667%;
			flex: 0 0 91.666667%;
			max-width: 91.666667%;
		}
		.col#{$bp}-12 {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
		}
		.order#{$bp}-first {
			-webkit-box-ordinal-group: 0;
			-ms-flex-order: -1;
			order: -1;
		}
		.order#{$bp}-last {
			-webkit-box-ordinal-group: 14;
			-ms-flex-order: 13;
			order: 13;
		}
		.order#{$bp}-0 {
			-webkit-box-ordinal-group: 1;
			-ms-flex-order: 0;
			order: 0;
		}
		.order#{$bp}-1 {
			-webkit-box-ordinal-group: 2;
			-ms-flex-order: 1;
			order: 1;
		}
		.order#{$bp}-2 {
			-webkit-box-ordinal-group: 3;
			-ms-flex-order: 2;
			order: 2;
		}
		.order#{$bp}-3 {
			-webkit-box-ordinal-group: 4;
			-ms-flex-order: 3;
			order: 3;
		}
		.order#{$bp}-4 {
			-webkit-box-ordinal-group: 5;
			-ms-flex-order: 4;
			order: 4;
		}
		.order#{$bp}-5 {
			-webkit-box-ordinal-group: 6;
			-ms-flex-order: 5;
			order: 5;
		}
		.order#{$bp}-6 {
			-webkit-box-ordinal-group: 7;
			-ms-flex-order: 6;
			order: 6;
		}
		.order#{$bp}-7 {
			-webkit-box-ordinal-group: 8;
			-ms-flex-order: 7;
			order: 7;
		}
		.order#{$bp}-8 {
			-webkit-box-ordinal-group: 9;
			-ms-flex-order: 8;
			order: 8;
		}
		.order#{$bp}-9 {
			-webkit-box-ordinal-group: 10;
			-ms-flex-order: 9;
			order: 9;
		}
		.order#{$bp}-10 {
			-webkit-box-ordinal-group: 11;
			-ms-flex-order: 10;
			order: 10;
		}
		.order#{$bp}-11 {
			-webkit-box-ordinal-group: 12;
			-ms-flex-order: 11;
			order: 11;
		}
		.order#{$bp}-12 {
			-webkit-box-ordinal-group: 13;
			-ms-flex-order: 12;
			order: 12;
		}
		.offset#{$bp}-0 {
			margin-left: 0;
		}
		.offset#{$bp}-1 {
			margin-left: 8.333333%;
		}
		.offset#{$bp}-2 {
			margin-left: 16.666667%;
		}
		.offset#{$bp}-3 {
			margin-left: 25%;
		}
		.offset#{$bp}-4 {
			margin-left: 33.333333%;
		}
		.offset#{$bp}-5 {
			margin-left: 41.666667%;
		}
		.offset#{$bp}-6 {
			margin-left: 50%;
		}
		.offset#{$bp}-7 {
			margin-left: 58.333333%;
		}
		.offset#{$bp}-8 {
			margin-left: 66.666667%;
		}
		.offset#{$bp}-9 {
			margin-left: 75%;
		}
		.offset#{$bp}-10 {
			margin-left: 83.333333%;
		}
		.offset#{$bp}-11 {
			margin-left: 91.666667%;
		}

	}

}

.isSpacer {
	@include bp-max(xs) {
		display: none;
	}
}

.grow {
	flex-grow: 1;
}

.noGrow {
	flex-grow: 0;
}

.noGrowDesktop {
	@include bp-min(sm) {
		flex-grow: 0;
	}
}

.noShrink {
	flex-shrink: 0;
}

.shrink {
	flex-shrink: 1;
}

.alignSelfCenter {
	align-self: center;
}
