.tuevInfo {
	p {
		font-size: 12px;
		color: #b0bdc0;
	}
	& > hr:first-child {
		margin-top: 0;
		margin-bottom: 0;
	}
	& > hr:last-child {
		margin-top: 17px;
	}
}

.tuevLogo {
	display: flex;
	flex-direction: row;
	margin: 15px 0px;
	padding-bottom: 10px;

	> a {
		float: left;
		margin-right: 12px;
		display: inline-block;
	}

	p {
		margin-left: 0;
		line-height: 1.3;
	}

	.tuevLogoImage {
		flex: 1 0 auto;
		background-size: contain;
		background-image: url('../../../../assets/img/tuev_logo.png');
		width: 75px;
		height: 40px;
	}
}

.icon {

	svg {
		fill: var(--color-turquoise);
		height: 40px;
		width: auto;
		transform: translate(0, -8px);
	}

}
