@import 'src/assets/styles/mixins/_breakpoints.scss';

.items {

	display: flex;
	flex-direction: column;
	gap: 1.25rem;

	&.itemsHasFocus {
		&.noSelection {
			.itemsWrapper {
				.inputWrapper {
					&:first-child {
						.input {
							& ~ .radio {
								box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
							}
						}
					}
				}
			}
		}
	}

}

.itemsWrapper {
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
}

.inputWrapper {

	position: relative;
	display: flex;
	flex-direction: row;
	gap: 0.5rem;

	&.alignCenter {
		justify-content: center;
	}

	&:hover {

		.input {

			&:not(:disabled):not(.error) {

				& ~ .radio {

					border-color: var(--color-darker-neutral);

					&.checked {
						border-color: var(--color-dark-turquoise);
					}

					.innerRadio {
						background-color: var(--color-dark-turquoise);
					}

				}

			}

		}

	}

}

.input {

	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

	&:checked {
		&.hasFocus {

			& ~ .radio {
				box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
			}

		}
	}

	&.error {

		& ~ .radio {

			border-color: var(--color-dark-red);

			.innerRadio {
				background-color: var(--color-dark-red);
			}

		}

	}

	&:disabled {

		& ~ .radio {

			background-color: var(--color-lighter-neutral);
			border-color: var(--color-light-neutral);
			cursor: not-allowed;

			.innerRadio {
				background-color: var(--color-light-neutral);
			}

		}

	}

}

.label {

	order: 2;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;

	@include bp-min(sm) {
		flex-direction: row;
	}

	&.labelDisabled {

		opacity: 0.5;
		cursor: not-allowed;

		& > * {
			cursor: not-allowed;
		}

	}

	.labelExtension {
		white-space: nowrap;
	}

}

.radio {

	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	position: relative;
	border: 1px solid var(--color-medium-neutral);
	background-color: var(--color-white);
	cursor: pointer;
	order: 1;
	flex-shrink: 0;

	.innerRadio {
		display: block;
		width: 0.75rem;
		height: 0.75rem;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		background-color: var(--color-turquoise);
	}

	&.checked {

		border-color: var(--color-turquoise);
		border-width: 2px;

		.innerRadio {
			opacity: 1;
		}
	}

}
