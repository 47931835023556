@import 'src/assets/styles/mixins/_breakpoints.scss';

.headerSpacer {

	width: 100%;
	height: 30px;

	@media (min-width: $sm-max) and (max-height: 699px) {
		height: 50px;
	}

	@media (min-width: $md-min) and (max-height: 699px) {
		height: 50px;
	}

	@media (min-width: $sm-max) and (min-height: 700px) {
		height: 50px;
	}

	@media (min-width: $md-min) and (min-height: 700px) {
		height: 80px;
	}

}
