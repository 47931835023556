.wrapper {

	$loadingSpinnerSizeM: 50px;
	$loadingSpinnerSizeL: 110px;

	$loadingSpinnerSizeMBarSpace: 6px;
	$loadingSpinnerSizeLBarSpace: 10px;

	display: inline-block;
	position: relative;
	border-radius: 50%;

	&.m {

		width: $loadingSpinnerSizeM;
		height: $loadingSpinnerSizeM;

		.loader {
			width: calc(#{$loadingSpinnerSizeM} - #{$loadingSpinnerSizeMBarSpace} * 2);
			height: calc(#{$loadingSpinnerSizeM} - #{$loadingSpinnerSizeMBarSpace} * 2);
		}

	}

	&.l {

		width: $loadingSpinnerSizeL;
		height: $loadingSpinnerSizeL;

		.loader {
			width: calc(#{$loadingSpinnerSizeL} - #{$loadingSpinnerSizeLBarSpace} * 2);
			height: calc(#{$loadingSpinnerSizeL} - #{$loadingSpinnerSizeLBarSpace} * 2);
		}

	}

}

.loader {

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 0.25rem solid var(--color-purple);
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;

}

@keyframes rotation {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}

.icon {

	width: 35%;
	height: 35%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	svg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		object-fit: contain;
		fill: var(--color-purple);
	}

}
