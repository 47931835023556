@import 'assets/styles/mixins/_breakpoints.scss';

.item {

	position: relative;

	.outerCircle {

		background-color: transparent;
		width: 2rem;
		height: 2rem;

		&,
		.innerCircle {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			border: 2px solid transparent;
		}

		.innerCircle {

			width: 1.75rem;
			height: 1.75rem;
			background-color: var(--color-turquoise);

			.step {
				color: var(--color-white);
				font: var(--typescale-body-large-font);
				font-weight: var(--typescale-strong-weight);
			}

			svg {
				fill: var(--color-white);
				transform: translate(0, 1.5px);
				width: 0.85rem;
				height: 0.785rem;
			}

		}

	}

	.stepName {

		color: var(--color-darker-neutral);
		font: var(--typescale-headline-xSmall-font);
		font-weight: var(--typescale-default-weight);
		display: none;
		position: absolute;
		bottom: -25px;
		left: 50%;
		white-space: nowrap;
		transform: translate(-50%, 0);

		@include bp-min(sm) {
			display: block;
		}

	}

	&.active {

		.outerCircle {

			background-color: var(--color-purple);

			.innerCircle {

				background-color: var(--color-purple);

				.step {
					color: var(--color-white);
				}
			}

		}

		.stepName {
			p {
				font-weight: var(--typescale-strong-weight);
			}
		}

	}


	&.disabled {

		.outerCircle {

			.innerCircle {

				background-color: transparent;
				border-color: var(--color-medium-sky);

				.step {
					color: var(--color-darker-neutral);
				}

			}

		}

	}

}
