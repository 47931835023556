.cssIcon {
	width: 1.5rem;
	height: 1.5rem;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	border-radius: 50%;
	overflow: hidden;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
