.illustrationWrapper {

	width: 100%;
	text-align: center;

	svg {
		width: 100%;
		height: 290px;
	}

}
