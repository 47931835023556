@import 'src/assets/styles/mixins/_breakpoints.scss';

.titleWrapper {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
}

.title {
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
}

.tooltip {
	width: 1.5rem;
	height: 1.5rem;
}

.icon {

	flex-shrink: 0;
	width: 1.5rem;
	height: 1.5rem;

	svg {
		width: 1.5rem;
		height: 1.5rem;
		fill: var(--color-red);
	}

}

.content {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
}

.description {

	&.fixedHeight {

		@include bp-min(md) {
			min-height: 40px;
		}

	}

}

.footer {
	position: relative;
}
