@import 'src/assets/styles/mixins/_breakpoints.scss';

.input {

	appearance: none;
	cursor: pointer;

	@include bp-min(sm) {
		display: none;
	}

}

.pseudoSelect {

	display: none;
	cursor: pointer;
	flex-direction: row;
	gap: 0.75rem;

	@include bp-min(sm) {
		display: flex;
	}

	&.pseudoSelectOpen {

		& ~ .arrow {

			svg {
				transform: rotate(180deg);
			}

		}

	}

}

.placeholder {
	color: var(--color-dark-neutral);
}

.pseudoItems {

	display: none;
	border-radius: var(--border-radius-default);
	background-color: var(--color-white);
	box-shadow: var(--box-shadow-default);
	width: 100%;
	max-height: calc((3rem * 5) + 0.75rem);
	overflow: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	position: absolute;
	left: 0;
	top: calc(var(--input-height) + 2px);
	z-index: 999;
	cursor: pointer;
	padding: 0.25rem;
	flex-direction: column;
	gap: 0.25rem;

	&.pseudoItemsOpen {

		@include bp-min(sm) {
			display: flex;
		}

	}

	&.smallItemBox {
		max-height: calc((3rem * 2) + 0.5rem + 0.75rem);
	}

}

.pseudoItemActiveCheck {
	width: 24px;
	height: 24px;
	display: none;
	position: absolute;
	top: 50%;
	right: 0.5rem;
	transform: translate(0, -50%);
}

.pseudoItem {

	padding: 0.75rem 0.5rem;
	display: flex;
	flex-direction: row;
	gap: 0.75rem;
	color: var(--color-darker-neutral);
	font: var(--typescale-body-large-font);
	font-weight: var(--typescale-default-weight);
	border-radius: var(--border-radius-small);
	position: relative;

	&.pseudoItemCursor,
	&:hover {
		background-color: var(--color-lighter-neutral);
	}

	&.pseudoItemActive {

		background-color: var(--color-light-turquoise);
		font-weight: var(--typescale-strong-weight);

		&.pseudoItemCursor,
		&:hover {
			background-color: var(--color-medium-light-turquoise);
		}

		.pseudoItemActiveCheck {
			display: block;
		}

	}

}

.pseudoItemIcon {
	position: relative;
	width: 1.5rem;
	height: 1.5rem;
}

.arrow {

	position: absolute;
	top: 50%;
	right: 0.75rem;
	transform: translate(0, -50%);
	z-index: 3;
	min-width: 1.5rem;
	height: 1.5rem;
	pointer-events: none;

	svg {
		width: 1.5rem;
		height: 1.5rem;
		display: inline-block;
		fill: var(--color-turquoise);
		transition: 0.2s;
	}

}
