@import 'src/assets/styles/mixins/_breakpoints.scss';

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.wrapper {
	background-color: var(--color-white);
	padding: 1rem;
	border-radius: var(--border-radius-default);
	box-shadow: var(--box-shadow-default);
	height: 100%;
}

.hasNoFilesMobile {

	@include bp-max(sm) {
		display: flex;
		flex-direction: row;
		padding: 0;

		.descriptionWrapper {
			padding: 16px;
			align-items: stretch;
			h3, p {
				hyphens: auto;
			}
		}

		.dropzoneWrapper {
			width: 120px;
			flex-shrink: 0;
			align-items: stretch;
			.dropzone,
			.dropzoneContentWrapper {
				height: 100%;
			}
			.dropzoneIcon {
				svg {
					* {
						fill: var(--color-white);
					}
				}
			}
		}

		.content {
			padding: 16px;
			flex-shrink: 1;
		}

		.title {
			h3 {
				word-wrap: break-word;
			}
		}

	}

	@media (max-width: 400px) {

		.dropzoneWrapper {
			width: 60px;
		}

		.dropzoneIcon {
			width: 35px;
			height: 35px;
		}

		.dropzoneText {

			p {
				font-size: 8px;
				line-height: 1.2;
			}

		}

		.content {
			padding: 10px;
		}

	}

}

.content {

	width: 100%;

	@include bp-min(md) {
		min-height: 150px;
	}

}

.title {

	width: 100%;

	@include bp-min(md) {
		min-height: 50px;
	}

	h3 {
		font-size: 16px;
		font-weight: 400;
		line-height: normal;
		margin: 0;
		margin-bottom: 10px;
	}

}

.list {
	margin-bottom: 15px;
}

.itemWrapper {
	display: flex;
	flex-direction: row;
	margin-bottom: 5px;
}

.item {

	flex-grow: 1;
	background-color: var(--color-light-turquoise);
	padding: 7px 10px;
	border-radius: 3px;
	display: flex;
	flex-direction: row;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@include bp-max(sm) {
		padding: 10px;
	}

}

.itemText {

	flex-grow: 1;

	display: flex;
	flex-direction: column;
	justify-content: center;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	p {
		width: 100%;
		padding-right: 15px;
		font-size: 11px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

}

.itemIcon {

	flex-shrink: 0;
	width: 12px;

	display: flex;
	flex-direction: column;
	justify-content: center;

	cursor: pointer;

	svg {
		width: 14px;
		height: 14px;
		fill: var(--color-darker-neutral);
	}

}

.itemUploadedFileIcon {

	flex-shrink: 0;
	width: 32px;
	height: 32px;
	margin-left: 10px;
	background-color: var(--color-turquoise);
	border-radius: 50%;
	position: relative;

	svg {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		fill: var(--color-white);
	}

}

.itemUploadedFileIconLoading {

	background-color: var(--color-light-turquoise);

	svg {
		animation-name: spin;
		animation-duration: 1500ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		width: 16px;
		height: 16px;
		position: absolute;
		top: 8px;
		left: 8px;
		fill: var(--color-turquoise);
	}

}

.dropzone {
	position: relative;
	width: 100%;
	cursor: pointer;
	overflow: hidden;
	border-radius: var(--border-radius-small);
}

.dropzoneBg {

	background-color: var(--color-lighter-purple);

	&:hover {
		background-color: var(--color-light-purple);
	}

}

.dropzoneDragActiveOverlay {

	opacity: 0.0000001;
	pointer-events: none;
	width: 100%;
	height: 100%;
	background-color: var(--color-lighter-purple);
	position: absolute;
	top: 0;
	left: 0;
	border: 1px solid var(--color-purple);
	transition: 0.1s;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	border-radius: var(--border-radius-small);

	&.dropzoneIsDragActive {
		opacity: 1;
		pointer-events: all;
	}

}

.dropzoneDragRejectedOverlay {

	opacity: 0.0000001;
	pointer-events: none;
	width: 100%;
	height: 100%;
	background-color: var(--color-light-red);
	position: absolute;
	top: 0;
	left: 0;
	border: 1px solid var(--color-medium-red);
	transition: 0.1s;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: var(--border-radius-small);

	&.dropzoneIsDragReject {
		opacity: 1;
		pointer-events: all;
	}

}

.dropzoneContentWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	justify-content: center;
	text-align: center;
	padding: 20px 10px 30px;
	position: relative;
	z-index: 1;
}

.dropzoneContentWrapperAdditional {

	width: 100%;
	display: flex;
	flex-direction: row;
	position: relative;
	z-index: 1;
	justify-content: center;

	@include bp-min(md) {
		justify-content: left;
	}

}

.dropzoneContentWrapperAdditionalIcon {

	flex-shrink: 0;
	width: 24px;
	height: 24px;

	svg {
		width: 24px;
		height: 24px;
	}

}

.dropzoneContentWrapperAdditionalText {

	flex-grow: 0;
	padding-left: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	p {

		font-size: 13px;
		margin: 0;

		.link {
			font-weight: var(--typescale-default-weight);
		}

	}

}

.dropzoneIcon {

	width: 45px;
	height: 45px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	background-color: var(--color-white);
	border-radius: 50%;
	margin-bottom: 15px;

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 60%;
		height: auto;
		fill: var(--color-purple);
	}

}

.dropzoneText {

	text-align: center;

	p {
		font-size: 12px;
		margin: 0;
		font-weight: var(--typescale-default-weight) !important;
	}

}

.uploadErrorWrapper {
	margin-top: 30px;
}
