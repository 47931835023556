@import 'src/assets/styles/mixins/_breakpoints.scss';

.footNote {
	width: 100%;
	position: absolute;
	bottom: 125px;
	left: 0;
	z-index: 1;

	@include bp-min(md) {
		bottom: 52px;
		padding: 0.75rem 1rem;
	}

}
