@import 'src/assets/styles/mixins/_breakpoints.scss';

.button {

	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: var(--color-purple);
	position: fixed;
	bottom: 10px;
	right: 50px;
	z-index: 9999999999;
	cursor: pointer;
	display: inline-block;
	margin-left: 15px;

	@include bp-min(md) {
		bottom: 100px;
		right: 10px;
	}

	svg {
		position: absolute;
		top: 50%;
		left: calc(50% + 2px);
		transform: translate(-50%, -50%);
		width: 20px;
		height: 20px;
		fill: var(--color-white);
	}

}
