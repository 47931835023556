.header {
	width: 100%;
}

.headerSticky {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2000;
	padding-left: 15px;
	padding-right: 15px;
}

.spacer {
	display: none;
}

.spacerSticky {
	display: block;
}
