@import 'src/assets/styles/mixins/_breakpoints.scss';

.title {
	font-weight: var(--typescale-strong-weight);
	color: var(--color-darker-neutral);
}

.s {

	font: var(--typescale-headline-mobile-small-font);

	@include bp-min(md) {
		font: var(--typescale-headline-small-font);
	}

	&.mb {
		margin-bottom: 0.5rem;
	}

	sub,
	sup {
		display: inline-block;
		font-size: 0.7rem;
	}

	sub {
		transform: translate(0, 6px);
	}

	sup {
		transform: translate(0, -6px);
	}

}

.m {

	font: var(--typescale-headline-mobile-medium-font);

	@include bp-min(md) {
		font: var(--typescale-headline-medium-font);
	}

	&.mb {
		margin-bottom: 0.5rem;
	}

	sub,
	sup {
		display: inline-block;
		font-size: 0.85rem;
	}

	sub {
		transform: translate(0, 7px);
	}

	sup {
		transform: translate(0, -7px);
	}

}

.l {

	font: var(--typescale-headline-mobile-large-font);

	@include bp-min(md) {
		font: var(--typescale-headline-large-font);
	}

	&.mb {
		margin-bottom: 1rem;
	}

	sub,
	sup {
		display: inline-block;
		font-size: 1rem;
	}

	sub {
		transform: translate(0, 7px);
	}

	sup {
		transform: translate(0, -7px);
	}

}

.xl {

	font: var(--typescale-headline-mobile-xlarge-font);

	@include bp-min(md) {
		font: var(--typescale-headline-xlarge-font);
	}

	&.mb {
		margin-bottom: 1.25rem;
	}

	sub,
	sup {
		display: inline-block;
		font-size: 1.25rem;
	}

	sub {
		transform: translate(0, 8px);
	}

	sup {
		transform: translate(0, -8px);
	}

}
