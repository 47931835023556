@import 'src/assets/styles/mixins/_breakpoints.scss';

.anchorCheckboxes {
	margin-top: 30px;
	position: relative;
	width: 100%;
	top: -100px;
}

.postidentExtension {

	text-align: center;
	white-space: nowrap;

	@include bp-min(sm) {
		text-align: right;
	}

}
