.wrapper {

	&:not(.removeMarginTop) {
		margin-top: 15px;
	}

	&:not(.removeMarginBottom) {
		margin-bottom: 15px;
	}

}

.hidden {
	display: none;
}

.alert {

	padding: 1.5rem;
	border-radius: var(--border-radius-default);
	display: flex;
	flex-direction: row;
	gap: 0.5rem;

	&.m {
		font: var(--typescale-body-large-font);
	}

	&.s {
		font: var(--typescale-caption-font);
	}

	&.error {

		background-color: var(--color-light-red);

		svg {
			fill: var(--color-dark-red);
		}

	}

	&.warning {

		background-color: var(--color-light-yellow);

		svg {
			fill: var(--color-dark-yellow);
		}

	}

	&.info {

		background-color: var(--color-sky);

		svg {
			fill: var(--color-decorative-sky);
		}

	}

	&.success {

		background-color: var(--color-light-green);

		svg {
			fill: var(--color-green);
		}

	}

}

.icon {
	flex-shrink: 0;
	display: block;
	width: var(--icon-size-m);
	height: var(--icon-size-m);
}

.content {
	flex-grow: 1;
}
