@import 'src/assets/styles/mixins/_breakpoints.scss';

.container {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 15px;
	padding-left: 15px;
}

@each $breakpoint in $breakpoints {
	@if $breakpoint {
		@include bp-min($breakpoint) {
			$min: getBpMin($breakpoint);
			@if $min {
				.container {
					max-width: $min - 30;
				}
			}
		}
	}
}
