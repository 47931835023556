@import 'src/assets/styles/mixins/_breakpoints.scss';

.devTerminal {

	position: fixed;
	bottom: 55px;
	right: 10px;
	z-index: 9999999999;

	@media (max-width: 1500px) {
		bottom: 60px;
	}

	@include bp-max(sm) {
		bottom: 60px;
		border-radius: 6px;
		background-color: var(--color-lighter-neutral);
	}

}

.devTerminalInner {

	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	@include bp-max(sm) {
		flex-direction: column;
		justify-content: flex-end;
		display: none;
		padding: 5px;
	}

	& > div {
		flex-grow: 0;
		flex-shrink: 0;
		&:not(:last-child) {
			margin-right: 10px;
			@include bp-max(sm) {
				margin-right: 0;
			}
		}
	}

}

.active {
	display: flex !important;
}

.mobileTrigger {

	display: none;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: var(--color-purple);
	position: absolute;
	right: 0;
	bottom: -50px;
	cursor: pointer;

	@include bp-max(sm) {
		display: block
	}

	span {
		color: var(--color-white);
		font-weight: bold;
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 12px;
		line-height: 1.0;
		transform: translate(-50%, -50%);
	}

}

.selectorInput {

	width: 200px;
	height: 28px;
	font-size: 10px;
	border-radius: 4px;
	border: 1px solid var(--color-dark-sky);
	background-color: var(--color-white);
	padding: 5px;
	outline: none;

	@include bp-max(sm) {
		width: 150px;
		margin-top: 5px;
	}

}

.selectorInputDisabled {
	opacity: 0.5;
}

.pagination {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	@include bp-max(sm) {
		width: 100%;
		justify-content: space-between;
	}
}

.paginationButton {

	width: 28px;
	height: 28px;
	padding: 0;
	outline: 0;
	border: 0;
	background-color: transparent;
	cursor: pointer;

	svg {
		transition: 0.2s;
		fill: var(--color-purple);
		width: 28px;
		height: 28px;
	}

	&:hover {
		svg {
			fill: var(--color-dark-purple);
		}
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.3;
	}

}
