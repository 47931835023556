@import 'src/assets/styles/mixins/_breakpoints.scss';

.illustration {

	padding-left: 15px;

	@include bp-min(sm) {
		padding-left: 30px;
	}

	@include bp-min(lg) {
		padding-left: 50px;
	}

	svg {

		height: auto;
		width: 100px;
		margin-bottom: 30px;

		@include bp-min(sm) {
			width: 170px;
		}

		@include bp-min(lg) {
			width: 243px;
		}

	}

}
