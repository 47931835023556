h1, h2, h3, h4, h5, h6 {
	line-height: 1.2;
}

.text {

	&--size {

		&--s {

			&,
			li {
				font: var(--typescale-caption-font);
			}

			& > svg,
			.link > svg {

				height: 1rem;
				width: auto;
				transform: translate(0, 3px);

				&:first-child {
					margin-right: 0.4rem;
				}

				&:last-child {
					margin-left: 0.4rem;
				}

			}

			sub,
			sup {
				display: inline-block;
				font-size: 0.5rem;
			}

			sub {
				transform: translate(0, 4px);
			}

			sup {
				transform: translate(0, -4px);
			}

		}

		&--m {

			&,
			li {
				font: var(--typescale-body-font);
			}

			& > svg,
			.link > svg {

				height: 1.25rem;
				width: auto;
				transform: translate(0, 4px);

				&:first-child {
					margin-right: 0.4rem;
				}

				&:last-child {
					margin-left: 0.4rem;
				}

			}

			sub,
			sup {
				display: inline-block;
				font-size: 0.65rem;
			}

			sub {
				transform: translate(0, 4px);
			}

			sup {
				transform: translate(0, -4px);
			}

		}

		&--l {

			&,
			li {
				font: var(--typescale-body-large-font);
			}

			& > svg,
			& > .link > svg {

				height: 1.25rem;
				width: auto;
				transform: translate(0, 4px);

				&:first-child {
					margin-right: 0.4rem;
				}

				&:last-child {
					margin-left: 0.4rem;
				}

			}

			sub,
			sup {
				display: inline-block;
				font-size: 0.75rem;
			}

			sub {
				transform: translate(0, 5px);
			}

			sup {
				transform: translate(0, -5px);
			}

		}


		&--xl {

			&,
			li {
				font: var(--typescale-body-xlarge-font);
			}

			& > svg,
			.link > svg {

				height: 1.75rem;
				width: auto;
				transform: translate(0, 7px);

				&:first-child {
					margin-right: 0.75rem;
				}

				&:last-child {
					margin-left: 0.75rem;
				}

			}

			sub,
			sup {
				display: inline-block;
				font-size: 0.875rem;
			}

			sub {
				transform: translate(0, 6px);
			}

			sup {
				transform: translate(0, -6px);
			}


		}

	}

	&--primary {
		&,
		li {
			color: var(--color-darker-neutral);
		}
		svg {
			fill: var(--color-darker-neutral);
		}
	}

	&--highlight {
		&,
		li {
			color: var(--color-turquoise);
		}
		svg {
			fill: var(--color-turquoise);
		}
	}

	&--action {
		&,
		li {
			color: var(--color-purple);
		}
		svg {
			fill: var(--color-purple);
		}
	}

	&--light {
		&,
		li {
			color: var(--color-dark-neutral);
		}
		svg {
			fill: var(--color-dark-neutral);
		}
	}

	&--white {
		&,
		li {
			color: var(--color-white);
		}
		svg {
			fill: var(--color-white);
		}
	}

	&--error {
		&,
		li {
			color: var(--color-medium-red);
		}
		svg {
			fill: var(--color-medium-red);
		}
	}

	&--success {
		&,
		li {
			color: var(--color-green);
		}
		svg {
			fill: var(--color-green);
		}
	}

}

.link {

	cursor: pointer;
	display: inline-block;
	outline: none;
	border-radius: var(--border-radius-small);

	&.link-disabled {
		pointer-events: none;
		opacity: 0.5;
		user-select: none;
	}

	&:focus,
	&:focus-visible {
		box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
	}

	&.primary {

		font-weight: var(--typescale-strong-weight);
		text-decoration: none;

		color: var(--color-purple);
		* {
			color: var(--color-purple);
		}
		svg {
			fill: var(--color-purple);
		}

		&:hover {

			color: var(--color-dark-purple);
			* {
				color: var(--color-dark-purple);
			}
			svg {
				fill: var(--color-dark-purple);
			}

		}

	}

	&.secondary {

		font-weight: var(--typescale-strong-weight);
		text-decoration: none;

		color: var(--color-turquoise);
		* {
			color: var(--color-turquoise);
		}
		svg {
			fill: var(--color-turquoise);
		}

		&:hover {

			color: var(--color-dark-turquoise);
			* {
				color: var(--color-dark-turquoise);
			}
			svg {
				fill: var(--color-dark-turquoise);
			}

		}

	}

	&.light {

		font-weight: var(--typescale-default-weight);
		text-decoration: none;

		color: var(--color-dark-neutral);
		* {
			color: var(--color-dark-neutral);
		}
		svg {
			fill: var(--color-dark-neutral);
		}

		&:hover {

			color: var(--color-dark-neutral);
			* {
				color: var(--color-dark-neutral);
			}
			svg {
				fill: var(--color-dark-neutral);
			}

		}

	}

}

ol {

	padding-left: 15px;
	list-style: decimal;

	li {

		&:not(:last-child) {
			margin-bottom: 0.5rem;
		}

	}

}

.list {

	ul, ol {

		li {

			position: relative;

			&:not(:last-child) {
				margin-bottom: 0.5rem;
			}

		}

	}

	ul {

		padding-left: 0;

		li {

			padding-left: 15px;
			list-style-type: none;

			&:before {
				content: '';
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: var(--color-turquoise);
				position: absolute;
				top: 8px;
				left: 0;
			}

		}
	}

	&--checks {
		ul {
			li {

				padding-left: 25px;

				&:before {
					top: 0;
					width: 1.25rem;
					height: 1.25rem;
					border-radius: 0;
					background-color: transparent;
					background-size: contain;
					background-position: top left;
					background-repeat: no-repeat;
					background-image: url(../../img/icons/svg_check_green.svg);
				}

			}
		}
	}

}

.no-wrap {
	white-space: nowrap;
}

@each $breakpoint in $breakpoints {

	@include bp-min($breakpoint) {

		$bp: '';
		@if $breakpoint {
			$bp: '-' + $breakpoint;
		}

		.text-align#{$bp} {
			&-left {
				text-align: left;
			}
			&-right {
				text-align: right;
			}
			&-center {
				text-align: center;
			}
		}

	}

}
