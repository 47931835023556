@import 'src/assets/styles/mixins/_breakpoints.scss';
@import 'src/assets/styles/mixins/_color.scss';

$totalValueBoxHeight: 165px;
$totalValueBoxHeightMobile: 150px;

.backgroundBlurOverlay {
	opacity: 0.000001;
	pointer-events: none;
	cursor: pointer;
	position: fixed;
	z-index: 1000001;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	max-height: 100vh;
	@include overlay-background;
	transition: opacity 0.3s;

	&.active {
		pointer-events: all;
		@include bp-min(sm) {
			opacity: 1;
		}
	}
}

.wrapper {

	position: relative;
	min-height: 100%;
	height: auto;
	padding-bottom: 46px;

	@include bp-max(sm) {
		padding-bottom: 80px;
	}

	@include bp-max(xs) {
		min-height: auto;
	}

}

.rightSideBar {

	position: fixed;
	top: 80px;
	right: -100vw;
	width: 100%;
	height: 100%;
	background-color: var(--color-white);
	z-index: 1000002;
	overflow-y: auto;
	transition: all 0.3s ease-out;
	border-top: 1px solid var(--color-light-neutral);

	@include bp-min(sm) {
		border-top: none;
		top: 0;
		width: 100%;
		max-width: 418px;
	}

	&.isOpen {
		right: 0;

		.sideBarOverlay {
			animation: fadeOutBlock 3s ease-out 0.7s 1 forwards;
		}

		.runtimeOverlay {
			animation: fadeOut 1.5s ease-out 0.7s 1 forwards;
		}

		.runtimeOverlayBottom {
			animation: fadeOut 1.5s ease-out 0.7s 1 forwards;
		}
	}

	.sideBarOverlay:nth-child(1) {
		height: 260px;
	}

	.sideBarOverlay:nth-child(2) {
		height: calc(100% - 480px);
		top: auto;
		bottom: 0;
	}

	.runtimeOverlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: var(--color-white);
		z-index: 100;
		opacity: 1;
		pointer-events: none;
		cursor: pointer;
	}

	.sideBarBlock {
		width: 100%;
		height: calc(100% - 165px);
		position: absolute;
		top: 165px;
		left: 0;
		background-color: rgba(255, 255, 255, 0.6);
		z-index: 103;
		opacity: 1;
		cursor: not-allowed;
	}

	@keyframes fadeOut {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	@keyframes fadeOutBlock {
		0% {
			opacity: 1;
		}
		30% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	.closeButton {

		width: var(--icon-size-m);
		height:  1.5rem;
		position: absolute;
		right: 15px;
		top: 15px;
		cursor: pointer;
		z-index: 106;
		display: block;
		outline: none;
		border-radius: var(--border-radius-small);

		&:focus,
		&:focus-visible {
			box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
		}

		svg {
			width: var(--icon-size-m);
			height:  1.5rem;
			fill: var(--color-darker-neutral);
		}

	}

}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
