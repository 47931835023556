@import 'src/assets/styles/mixins/_breakpoints.scss';

.moreDetailsLink {
	display: inline-block;
	position: relative;
	margin-top: 15px;
	margin-bottom: 5px;
}

.moreDetailsWrapper {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-out;
	padding-bottom: 5px;
	pointer-events: none;
}

.moreDetailsWrapperActive {
	max-height: 1000px;
	transition: max-height 0.3s ease-in;
	pointer-events: all;
}


.table {
	display: table;
	width: 100%;
}

.tableRow {
	display: table-row;
}

.tableCell {
	display: table-cell;
	padding-top: 15px;
}

.tableCellLabel {

	width: 180px;

	@include bp-min(md) {
		width: auto;
	}

}

.tableCellValue {
	padding-left: 10px;
	width: auto;
}
