@import 'src/assets/styles/mixins/_breakpoints.scss';

.titleGraphic {

	text-align: center;

	&.titleGraphicHideMobile {
		@include bp-max(xs) {
			display: none;
		}
	}

	img {
		min-height: 126px;
	}

	svg,
	img {
		width: 100%;
		height: auto;
		max-width: 200px;
	}

	.titleGraphicSmall {

		svg,
		img {
			max-width: 120px;
		}

	}

}
