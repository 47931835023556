@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

	margin-top: 15px;
	text-align: center;

	@include bp-min(sm) {
		margin-top: 15px;
		text-align: left;
	}

}

.row {

	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	margin-top: 15px;
	margin-bottom: 10px;

	@include bp-min(sm) {
		flex-direction: row;
		margin-top: 10px;
		margin-bottom: 20px;
	}

}

.itemWrapper {

	display: flex;
	flex-direction: column;
	flex-grow: 1;
	align-items: stretch;

	@include bp-min(sm) {
		flex-direction: row;
	}

}

.hideItem {
	display: none;
}

.item {

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-grow: 1;
	margin-bottom: 5px;
	align-self: center;

	@include bp-min(sm) {
		text-align: center;
		margin-bottom: 0;
	}

}

.noGrow {

	@include bp-min(sm) {
		flex-grow: 0;
	}

}

.title {

	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;

	@include bp-min(sm) {
		text-align: center;
	}

}

.arrow {

	height: 25px;
	margin-left: 15px;
	margin-right: 15px;
	position: relative;

	@include bp-min(sm) {
		height: 40px;
		width: 40px;
	}

	svg {

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(90deg);
		fill: var(--color-purple);
		height: 100%;
		width: auto;

		& > * {
			fill: var(--color-purple);
		}

		@include bp-min(sm) {
			transform: translate(-50%, -50%);
		}

	}

}
