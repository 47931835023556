@import 'src/assets/styles/mixins/_breakpoints.scss';

.suffix {

	height: calc(100% - 4px);
	aspect-ratio: 1/1;
	background: var(--color-white);
	position: absolute;
	border-radius: var(--border-radius-default);
	top: 2px;
	right: 2px;
	z-index: 1;
	cursor: pointer;
	pointer-events: none;

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: block;
		fill: var(--color-turquoise);
	}

}
