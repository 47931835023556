@import 'src/assets/styles/mixins/_breakpoints.scss';

.popUpIcon {

	text-align: center;
	margin-top: 15px;
	margin-bottom: 15px;

	@include bp-min(sm) {
		margin-top: 60px;
		margin-bottom: 30px;
	}

}

.toUpsellDownsellLink {
	margin-top: 20px;
	text-align: right;
}
