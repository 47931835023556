.resendLink {
	text-align: right;
}

.resendLinkSuccess {

	cursor: default !important;
	color: var(--color-green) !important;

	svg {
		fill: var(--color-green) !important;
	}

}
