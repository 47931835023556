.wrapper {

	flex-grow: 1;
	height: 0;
	min-width: 1px;
	border-top: 2px solid var(--color-turquoise);

	&.disabled {
		border-color: var(--color-medium-sky);
	}

}
