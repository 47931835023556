@import 'src/assets/styles/mixins/_breakpoints.scss';

.buttonWrapper {
	@include bp-max(sm) {
		text-align: center;
		& > div {
			display: flex;
			flex-direction: row;
			justify-content: center;
		}
	}
}

.submitFooter {
	margin-top: 1.25rem;
}

.secureConnection {

	@include bp-max(md) {
		display: none;
	}

}

.submitExtension {
	margin-bottom: 1rem;
}

.prevButtonExtension {
	@include bp-max(sm) {
		margin-top: 0.5rem;
	}
}

.alerts {
	margin-top: 2rem;
}

.submitOnlyWrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
