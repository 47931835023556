.__react_component_tooltip {

	border-radius: var(--border-radius-default) !important;
	padding: 1rem !important;
	max-width: 300px !important;
	z-index: 99999999999 !important;

	@include bp-min(sm) {
		max-width: 500px !important;
	}

	&.show {
		opacity: 1 !important;
	}

}

.type-light {

	background-color: var(--color-white) !important;
	box-shadow: none !important;
	border: 1px solid var(--color-light-neutral) !important;

	&,
	* {

		font: var(--typescale-body-font) !important;
		color: var(--color-darker-neutral) !important;

		strong {
			font-weight: bold !important;
		}

	}

	&.place-top {
		&:after {
			border-top-color: var(--color-white) !important;
		}
		&:before {
			border-top-color: var(--color-light-neutral) !important;
		}
	}

	&.place-bottom {
		&:after {
			border-bottom-color: var(--color-white) !important;
		}
		&:before {
			border-bottom-color: var(--color-light-neutral) !important;
		}
	}

	&.place-left {
		&:after {
			border-left-color: var(--color-white) !important;
		}
		&:before {
			border-left-color: var(--color-light-neutral) !important;
		}
	}

	&.place-right {
		&:after {
			border-right-color: var(--color-white) !important;
		}
		&:before {
			border-right-color: var(--color-light-neutral) !important;
		}
	}

	ul {

		padding-left: 15px;

		li {
			padding-left: 0;
			list-style-type: disc;
		}

	}

}
