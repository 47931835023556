@import 'src/assets/styles/mixins/_breakpoints.scss';

.illustrationWrapper {

	width: 100%;
	text-align: center;

	svg {
		width: 100%;
		height: 290px;
	}

}

.textGroup {

	position: relative;
    top: 50%;
	transform: translateY(-45%);
	padding-bottom: 20px;

}
