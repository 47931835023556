@import 'src/assets/styles/mixins/_breakpoints.scss';

.name {
	font-size: 16px;
	font-weight: 300;
	margin-bottom: 25px
}

.finalStepsRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	padding-top: 0.15rem;
	padding-bottom: 0.15rem;
}

.finalStepsBox {
	height: 100%;
}

.finalSteps {
	background-color: var(--color-white);
	border-radius: var(--border-radius-default);
	box-shadow: var(--box-shadow-default);
	padding: 1rem;
	height: 100%;
}

.statusLabel {
	display: block;
	flex-shrink: 0;
}

.statusDotWrapper {
	flex-shrink: 0;
	width: 8px;
	height: 24px;
	margin-right: 10px;
	position: relative;
	display: inline-block;
}

.statusDot {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--color-medium-red);
}

.finished {

	background-color: var(--color-white);
	border-radius: var(--border-radius-default);
	box-shadow: var(--box-shadow-default);
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;

	svg {
		height: 50px;
		width: auto;
		margin-top: 1rem;
		flex: 0 0 auto;
		fill: var(--color-green);
	}

	.name {
		width: 100%;
		text-align: center;
		background-color: white;
		margin-top: 1rem;
	}

}

.buttonWrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 1rem;
}

.status {
	white-space: nowrap;
	flex-grow: 1;
	width: 100%;

	@include bp-min(md) {
		width: auto;
		text-align: right;
	}
}

.uploaded {
	.statusDot {
		background-color: var(--color-green);
	}
}

.missing {
	.statusDot {
		background-color: var(--color-yellow);
	}
}

