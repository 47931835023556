@import 'src/assets/styles/mixins/_breakpoints.scss';

.detailsLink {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin-top: 10px;
}

.detailsTable {
	display: table;
	width: 100%;
	padding-top: 15px;
}

.detailsTableRow {
	display: table-row;
}

.detailsTableCell {
	display: table-cell;
	padding-bottom: 15px;
}

.detailsTableCellLabel {}

.detailsTableCellValue {
	text-align: right;
}

.upsellDownsellMoreDetailsWrapper {
	max-height: 0;
	overflow: hidden;
	transition: 0.3s ease-out;
}

.upsellDownsellMoreDetailsWrapperActive {
	max-height: 1000px;
	transition: 0.3s ease-in;
}
