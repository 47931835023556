@import 'src/assets/styles/mixins/_breakpoints.scss';

.headerOuter {

	background-color: var(--color-white);
	position: relative;
	z-index: 9999;
	padding: 0 15px;
	box-shadow: var(--box-shadow-default);

	@include bp-min(md) {
		padding: 0 30px;
	}

	@include bp-min(lg) {
		padding: 0 40px;
	}

}

.headerWrapper {

	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;

	@include bp-min(sm) {
		height: 95px;
	}

}

.logo {
	width: 40px;
	height: 95px;
	padding-bottom: 6px;
	position: relative;
	display: flex;
	align-items: center;
	flex-shrink: 0;

	svg {
		width: 35px;
		height: 35px;
	}

	&::after {
		content: '';
		width: 1px;
		height: 30px;
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		right: 0;
		top: 33px;
		display: none;
	}

	&.logoLoading {
		&::after {
			background-color: transparent;
		}
	}

	@include bp-min(md) {

		width: 165px;

		svg {
			width: 148px;
			height: 26px;
		}

		&::after {
			display: block;
		}

	}
}

.headerHeadline {
	flex: 1;
	min-width: 0;
	padding-right: 30px;
}

.headerTitle {

	font: 0.875rem/1.25rem var(--font-family-headline);
	padding: 0;
	margin: 0;
	margin-left: 10px;
	width: 100%;

	flex: 1;
	min-width: 0;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@include bp-min(md) {
		font: 0.875rem/1.25rem var(--font-family-headline);
		font-size: 20px;
		margin-left: 15px;
		display: inline-block;

		br {
			display: none;
		}

	}

	@include bp-min(lg) {
		font: 1.5rem/2rem var(--font-family-headline);
		padding-top: 0;
	}

	strong {
		display: inline-block;
		font-weight: var(--typescale-strong-weight);
		margin-right: 10px
	}

	svg {
		height: 12px;
		width: auto;
		@include bp-min(md) {
			height: 18px;
		}
	}

}

.isDesktop {

	display: none;

	@include bp-min(md) {
		display: block;
	}

}

.isMobile {

	display: block;

	@include bp-min(md) {
		display: none;
	}

}

.secureConnection {

	position: absolute;
	top: 5px;
	right: 10px;
	display: none;

	@include bp-max(xs) {
		display: block;
	}

}

.creditInfosDesktop {

	display: none;

	flex-direction: row;
	align-items: flex-end;
	height: 100%;
	max-width: 100%;
	flex-shrink: 0;
	border-left: solid 1px var(--color-light-neutral);
	padding-left: 15px;

	@include bp-min(sm) {
		display: flex;
	}

	@include bp-min(md) {
		padding-left: 30px;
	}

	@include bp-min(lg) {
		padding-left: 40px;
	}

}

.creditInfosMobile {

	display: flex;
	flex-direction: row;
	height: 100%;
	flex-shrink: 0;
	background-color: var(--color-white);
	margin-left: -15px;
	margin-right: -15px;
	padding: 10px 15px;
	border-top: 1px solid var(--color-sky);

	@include bp-min(sm) {
		display: none;
	}

	p {
		white-space: nowrap;
	}

}
