@import 'src/assets/styles/mixins/_breakpoints.scss';

.box {
	background-color: var(--color-white);
	border-radius: var(--border-radius-default);
	box-shadow: var(--box-shadow-default);
	padding: 1rem;
	margin-bottom: 1rem;
}

.header {
	display: flex;
	flex-direction: row;
}

.hasBody {

	.header {

		padding-bottom: 1rem;

		@include bp-min(sm) {
			padding-bottom: 2rem;
		}

	}

}

.stretch {
	height: 100%;
}

.headerIcon {

	flex-grow: 0;

	svg {

		width: var(--icon-size-m);
		height: var(--icon-size-m);
		margin-right: 10px;
		fill: var(--color-purple);

		@include bp-min(sm) {
			margin-right: 20px;
		}

	}

}

.headerContent {
	flex-grow: 1;
}

.subtitle {
	margin-top: 0;
	margin-bottom: 0;
	line-height: 1.2;
}

.body {
	overflow: hidden;
	max-height: 0;
	transition: 0.5s;
	padding: 0.25rem;
}

.divider {
	width: 0;
	height: 1px;
	background-color: var(--color-light-neutral);
	margin-bottom: 15px;
	margin-left: auto;
	margin-right: auto;
	transition: 0.5s;
}

.toggle {
	text-align: right;
	padding-top: 1rem;
}

.active {

	.body {
		max-height: 1000px;

		@include bp-min(sm) {
			max-height: 1000px;
		}

	}

	.divider {
		width: 100%;
	}

}
