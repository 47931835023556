@import 'src/assets/styles/mixins/_breakpoints.scss';

.icon {

	width: var(--icon-size-m);
	height: var(--icon-size-m);
	display: inline-block;
	cursor: pointer;

	svg {
		width: var(--icon-size-m);
		height: var(--icon-size-m);
		fill: var(--color-purple);
	}

}

.iconTooltip {
	@include bp-max(sm) {
		display: none;
	}
}

.iconModal {

	display: none;

	@include bp-max(sm) {
		display: block;
	}

}
