@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

	display: inline-flex;
	flex-direction: row;
	background-color: var(--color-light-sky);
	padding: 0.5rem 1rem;
	border-radius: var(--border-radius-default);

	margin-top: 10px;
	margin-bottom: 20px;

	@include bp-min(sm) {
		margin-top: 0;
		margin-bottom: 20px;
		padding: 0.5rem 1.5rem;
	}

	svg {
		position: relative;
		margin-right: 5px;
		left: -3px;
		fill: var(--color-red);
		flex-shrink: 0;
		height: var(--icon-size-m);
		width: var(--icon-size-m);
		transform: translate(0, 0);
	}

}
