@import 'src/assets/styles/mixins/_breakpoints.scss';

.sparbriefImg {

	text-align: center;

	img {
		display: inline-block;
		width: 100%;
		max-width: 170px;
		height: auto;
		margin-bottom: 15px;
	}

}

.euro {

	text-align: center;
	transform: translate(0, -10px);

	@include bp-min(md) {
		margin-bottom: 30px;
	}

	svg {
		width: 100%;
		height: auto;
		max-width: 120px;
	}
}

.sparbriefHintBox {
	margin-top: 30px;
	padding: 15px 20px;
	border: 1px solid var(--color-turquoise);
	border-radius: 12px;
	display: flex;
	flex-direction: row;
}

.sparbriefHintBoxIcon {

	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@include bp-min(md) {
		justify-content: center;
	}

	svg {

		width: 50px;
		height: auto;
		padding-right: 15px;
		fill: var(--color-light-neutral);

		@include bp-min(md) {
			width: 100px;
			padding-right: 30px;
		}

	}

}

.card {

	margin-bottom: 30px;

	@include bp-max(xs) {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	img, svg {

		width: 100%;
		max-width: 170px;
		height: auto;

	}

}
