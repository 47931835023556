@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

	margin-top: -40px;
	box-shadow: var(--box-shadow-default);
	border-radius: 6px;
	overflow: hidden;

	@include bp-max(sm) {
		margin-top: -10px;
	}

}

.header {

	width: 100%;
	height: 90px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(../../../../assets/img/thank_you_credit_header.png);

	@include bp-max(sm) {
		background-position: -80px center;
	}

}

.content {

	padding: 25px;
	background-color: var(--color-white);

	@include bp-min(md) {
		padding: 25px 30px;
	}

}
