@import 'src/assets/styles/mixins/_breakpoints.scss';

.card {

	margin-bottom: 30px;

	@include bp-max(xs) {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	img, svg {

		width: 100%;
		max-width: 170px;
		height: auto;

	}

}
