@import 'src/assets/styles/mixins/_breakpoints.scss';

.image {

	@include bp-max(sm) {
		justify-content: center;
		display: flex;
		flex-direction: row;
		margin-bottom: 15px;
	}

	svg {

		width: 100%;
		height: auto;
		max-width: 170px;

		@include bp-max(sm) {
			max-width: 100px;
		}

		@include bp-max(xs) {
			max-width: 150px;
		}

	}

}

.title {

	transform: translate(0, 30px);

	@include bp-max(sm) {
		transform: translate(0, 10px);
	}

	@include bp-max(xs) {
		transform: none;
	}

}

.box {

	background-color: var(--color-white);
	border-radius: var(--border-radius-default);
	border: 1px solid var(--color-turquoise);
	overflow: hidden;

	padding: 1rem;

	.banner {
		padding: 5px 10px 7px;
		background-color: var(--color-turquoise);
		color: var(--color-white);
		font-weight: bold;
		text-align: center;
		font-size: 13px;
		line-height: 1.2;
		width: calc(100% + 2rem);
		margin: -1rem -1rem 1rem;
	}

}

.list {

	margin-top: -20px;
	padding-left: 40px;

	ul {

		li {

			display: flex;
			flex-direction: row;
			gap: 10px;

			.tooltipWrapper {

				display: block;
				height: 1.25rem;
				width: var(--icon-size-m);
				position: relative;
				flex-shrink: 0;

				.tooltip {

					height: var(--icon-size-m);
					width: var(--icon-size-m);
					display: block;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translate(0, -50%);

					svg {
						fill: var(--color-purple);
					}

				}

			}

		}

	}

}
