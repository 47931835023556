@import 'assets/styles/mixins/_breakpoints.scss';

.wrapper {

	display: flex;
	z-index: 0;
	margin-bottom: 10px;

	@include bp-min(sm) {
		margin-bottom: 70px;
	}

}

.progressBar {
	display: flex;
	flex-grow: 1;
	gap: 0.25rem;
}

.spacer {

	@include bp-min(sm) {
		flex-grow: 1;
		max-width: 50px;
	}

}

.connector {
	flex-grow: 1;
	margin-top: 15px;
}
