@import 'src/assets/styles/mixins/_breakpoints.scss';

.iconGroup {

	svg {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		padding: 10px;
		margin: auto;
		width: 40%;
		height: auto;

		@include bp-min(sm) {
			width: 50%;
		}

		@include bp-min(md) {
			width: 90%;
		}

	}
}

.textGroup {
	position: relative;
    top: 50%;
	transform: translateY(-50%);
}

.hr {
	height: 2px;
	background-color: var(--color-sky);
	border: none;
}
