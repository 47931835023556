@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

	margin-top: -40px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
	border-radius: 6px;
	overflow: hidden;

	@include bp-max(sm) {
		margin-top: -10px;
	}

}

.header {

	width: 100%;
	height: 90px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(../../../../../assets/img/bco_response_green_credit_header.png);

	@include bp-max(xs) {
		display:none;
	}

}

.content {

	padding: 25px;
	background-color: var(--color-white);

	@include bp-min(md) {
		padding: 25px 30px;
	}

}

.contentLeft {
	position: relative;
	height: 100%;
}

.contentRight {
	height: 100%;
	padding-top: 20px;
}

.amount {

	color: var(--color-turquoise);
	font: var(--typescale-headline-mobile-small-font);

	* {
		color: var(--color-turquoise);
	}

	strong {
		font: var(--typescale-headline-mobile-xlarge-font);
	}

}

.applicationId {

	margin-bottom: 20px;
	position: relative;
	display: inline-block;

	&:after {
		content: '';
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		bottom: -5px;
		background-color: var(--color-dark-neutral);
	}

}
