@import 'src/assets/styles/mixins/_breakpoints.scss';

.item {

	position: relative;
	margin-bottom: 10px;

	&:not(:last-child) {
		&:after {

			content: '';
			display: block;
			width: 2px;
			height: calc(100% - 30px);
			position: absolute;
			background-color: var(--color-turquoise);
			z-index: 0;

			top: calc(100% - 14px);
			left: 50%;
			transform: translate(-1px, 0);

			@include bp-min(sm) {
				top: 50%;
				left: 16px;
				transform: translate(20px, 20px);
				z-index: 100;
			}

		}
	}

}

.content {

	display: flex;
	flex-direction: column;
	gap: 15px;
	align-items: center;

	@include bp-min(sm) {
		flex-direction: row;
		gap: 20px;
	}

}

.number {

	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: solid 2px var(--color-turquoise);
	background-color: #ffffff;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 0;
	flex-shrink: 0;
	position: relative;
	font-size: 16px;
	order: 3;

	@include bp-min(sm) {
		order: 1;
	}

}

.text {

	flex-grow: 1;
	order: 2;
	text-align: center;

	@include bp-min(sm) {
		text-align: left;
	}

	h1, h2 {
		margin-bottom: 0;
	}

}

.icon {

	position: relative;
	flex-grow: 0;
	flex-shrink: 0;
	width: 80px;
	height: 80px;
	order: 1;

	@include bp-min(sm) {
		order: 3;
	}

	svg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		object-fit: contain;
	}

}
