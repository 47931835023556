@import 'src/assets/styles/mixins/_breakpoints.scss';

.image {

	@include bp-max(sm) {
		justify-content: center;
		display: flex;
		flex-direction: row;
		margin-bottom: 15px;
	}

	svg {

		width: 100%;
		height: auto;
		max-width: 260px;

		@include bp-max(sm) {
			max-width: 200px;
		}

		@include bp-max(xs) {
			max-width: 150px;
		}

	}

}

