.rangeSlider {
    width: 100%;
}

.row {
    display: flex;
	flex-direction: row;
    align-items: center;
}

.rangeLabel {
	font: var(--typescale-body-large-font);
	flex-grow: 1;
}

.rangeInput {
	flex-shrink: 0;
	font: var(--typescale-body-large-font);
	font-weight: var(--typescale-strong-weight);
}

.suffix {
	flex-shrink: 0;
	font: var(--typescale-body-large-font);
	font-weight: var(--typescale-strong-weight);
	margin-left: 5px;
}

.tooltipRow {
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	gap: 0.25rem;
}
