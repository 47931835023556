.wrappper {

	display: flex;
	flex-direction: row;

	svg {
		flex-shrink: 0;
		margin-right: 6px;
		fill: var(--color-dark-neutral);
		width: 18px;
		height: 18px;
		transform: translate(0, -2px);
	}

	p {
		white-space: nowrap;
	}

}
