@import 'src/assets/styles/mixins/_breakpoints.scss';
@import 'src/assets/styles/mixins/_color.scss';

.appointmentBox {

	$boxWidth: 270px;

	position: fixed;
	width: $boxWidth;
	top: 180px;
	right: -$boxWidth;
	z-index: 1000001;
	transition: right 0.3s;

	@include bp-max(xs) {
		top: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		pointer-events: none;
	}

}

.appointmentBoxActive {
	right: 0;
	pointer-events: all;
}

.appointmentButtonClose {

	cursor: pointer;
	width: var(--icon-size-m);
	height: var(--icon-size-m);
	position: absolute;
	z-index: 3;
	top: 1rem;
	right: 1rem;
	outline: none;
	border-radius: var(--border-radius-small);

	svg {
		width: var(--icon-size-m);
		height: var(--icon-size-m);
		fill: var(--color-darker-neutral);
	}

	&:focus,
	&:focus-visible {
		box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
	}

}

.appointmentBoxContent {

	position: relative;
	z-index: 2;
	width: 100%;
	height: auto;
	min-height: 122px;
	padding: 1rem;
	background-color: var(--color-white);
	box-shadow: var(--box-shadow-default);
	border-top-left-radius: var(--border-radius-small);
	border-bottom-left-radius: var(--border-radius-small);
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	@include bp-max(xs) {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-height: 0;
		opacity: 0;
		pointer-events: none;
		border-radius: var(--border-radius-default);
		border: var(--border-radius-default);
		transition: opacity 0.3s;
		width: calc(100% - 1.5rem);
	}

}

.appointmentBoxContentActive {
	opacity: 1;
	pointer-events: all;
}

.appointmentBoxRow {
	display: flex;
	flex-direction: row;
	gap: 0.75rem;
}

.appointmentBoxIcon {

	flex-shrink: 0;
	position: relative;

	width: var(--icon-size-m);
	height: var(--icon-size-m);

	svg {
		position: relative;
		width: var(--icon-size-m);
		height: var(--icon-size-m);
		fill: var(--color-red);
	}

}

.appointmentBoxText {
	flex-grow: 1;
}

$activateButtonWidth: 50px;
$activateButtonWidthMobile: 30px;

.appointmentButton {

	position: absolute;
	left: -$activateButtonWidth;
	top: 10px;
	width: $activateButtonWidth;
	background-color: var(--color-purple);
	border-top-left-radius: var(--border-radius-default);
	border-bottom-left-radius: var(--border-radius-default);
	cursor: pointer;
	z-index: 1;
	box-shadow: var(--box-shadow-default);
	padding-top: 1rem;
	padding-bottom: 0.75rem;
	outline: none;

	@include bp-max(xs) {
		left: auto;
		top: auto;
		bottom: 75px;
		right: 0;
		width: $activateButtonWidthMobile + 15px;
		padding-top: 10px;
		padding-bottom: 10px;
		pointer-events: all;
	}

	&:hover {

		@include bp-min(sm) {
			background-color: var(--color-dark-purple);
		}

	}

	&:focus,
	&:focus-visible {
		box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
	}

}

.appointmentButtonTitle {

	color: var(--color-white);
	font: var(--typescale-caption-font);
	font-weight: var(--typescale-strong-weight);
	transform: rotate(-90deg);
	height: $activateButtonWidth;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: right;

	@include bp-max(xs) {
		display: none;
	}

}

.appointmentButtonIcon {

	width: $activateButtonWidth;
	display: flex;
	flex-direction: row;
	justify-content: center;

	@include bp-max(xs) {
		width: $activateButtonWidthMobile + 15px;
	}

	svg {

		fill: var(--color-white);
		width: var(--icon-size-m);
		height: var(--icon-size-m);

		@include bp-max(xs) {
			width: var(--icon-size-m);
			height: var(--icon-size-m);
		}

	}

}


.appointmentCloseBackground {

	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	@include overlay-background;
	transition: opacity 0.3s;
	width: 100%;
	height: 100%;
	display: none;
	cursor: pointer;
	opacity: 0.0000000001;
	pointer-events: none;

	@include bp-max(xs) {
		display: block;
	}

}

.appointmentCloseBackgroundActive {
	opacity: 1;
	pointer-events: all;
}
