@import 'src/assets/styles/mixins/_breakpoints.scss';

.box {

	display: flex;
	flex-wrap: nowrap;
	border-bottom: 1px solid var(--color-light-neutral);
	margin-bottom: 15px;

	@include bp-min(md) {
		margin-bottom: 30px;
	}

}

.col {

	position: relative;
	width: 33.3333%;
	height: 70px;

	@include bp-min(md) {
		height: 100px;
	}

}

.label {

	font-size: 13px;
	margin-top: 10px;

	@include bp-min(md) {
		margin-top: 18px;
		font-size: 13px;
	}

	@include bp-min(lg) {
		font-size: 14px;
	}

}

.text {

	font-size: 13px;
	margin-top: 10px;

	@include bp-min(md) {
		font-size: 16px;
	}

	@include bp-min(lg) {
		font-size: 18px;
	}

}
