.wrapper {

	svg {
		transition: all var(--transition-default-time) ease-in-out;
	}

}

.active {

	:global(.text--size--s) {

		svg {
			transform: translate(0, 3px) rotate(-180deg) !important;
		}

	}

	:global(.text--size--m) {

		svg {
			transform: translate(0, 4px) rotate(-180deg) !important;
		}

	}

	:global(.text--size--l) {

		svg {
			transform: translate(0, 5px) rotate(-180deg) !important;
		}

	}

	:global(.text--size--xl) {

		svg {
			transform: translate(0, 7px) rotate(-180deg) !important;
		}

	}

}
