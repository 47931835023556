.wrapper {

	position: fixed;
	z-index: 1000001;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0);
	display: none;

	&.active {
		display: block;
	}

}
