@import 'src/assets/styles/mixins/_breakpoints.scss';

$checkboxSizeDesktop: 30px;
$checkboxSizeMobile: 24px;

.table {

	display: flex;
	flex-direction: row;
	margin-top: 30px;
	margin-bottom: 30px;

	@include bp-min(sm) {
		margin-top: 30px;
		margin-bottom: 60px;
	}

}

.col {
	flex-grow: 1;
	flex-shrink: 0;
	position: relative;
	border-radius: 5px;
	cursor: pointer;
}

.colRow {

	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;

	&:not(.colRowHeadline):not(:last-child) {
		border-bottom: solid 1px var(--color-light-neutral);
	}

}

.colWithHighlightLabel {
	border: 2px solid var(--color-turquoise);
	border-top: none;
}

.colIsFirst {

	max-width: 100px;
	cursor: default;

	@include bp-min(sm) {
		max-width: 150px;
	}

	@include bp-min(md) {
		max-width: 200px;
	}

}

.colSpacer {

	display: flex;
	flex-direction: row;

	height: 30px;

	@include bp-min(md) {
		height: 30px;
	}

	p {

		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
		font-size: 8px;
		line-height: 1.2;
		color: var(--color-darker-neutral);
		flex-grow: 1;

		@include bp-min(sm) {
			font-size: 12px;
		}

		@include bp-min(md) {
			font-size: 14px;
		}

	}

}

.colEmpty,
.colLabel,
.colCheck {

	height: 65px;

	@include bp-min(md) {
		height: 80px;
	}

}

.colHeadline,
.colHeadlineSpacer {

	height: 90px;

	@include bp-min(md) {
		height: 100px;
	}

}

.colBtn {

	height: 40px;

	@include bp-min(md) {
		height: 40px;
	}

}

.colLabel {

	display: flex;
	flex-direction: row;

	p {

		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
		font-size: 8px;
		line-height: 1.2;
		color: var(--color-darker-neutral);
		flex-grow: 1;

		@include bp-min(sm) {
			font-size: 12px;
		}

		@include bp-min(md) {
			font-size: 14px;
		}

	}

	.popupIcon {

		flex-shrink: 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-self: center;
		margin-left: 10px;
		cursor: pointer;

		svg {
			fill: var(--color-purple);
		}

	}

}

.popup {
	width: 100%;
	position: relative;
	padding-bottom: 10px;
}

.popupHeader {

	display: flex;
	flex-direction: row;

	&:not(:first-child) {
		margin-top: 30px;
	}

}

.popupHeaderIcon {

	flex-grow: 0;
	padding-right: 15px;

	svg {
		width: 40px;
		height: auto;
	}

}

.popupHeadline {
	position: relative;
	font-size: 18px !important;
	color: var(--color-darker-neutral) !important;
}

.popupText {

	margin-top: 15px !important;
	margin-bottom: 20px !important;

	ul {

		list-style-type: none;
		padding-left: 0;

		li {

			padding-left: 15px;
			position: relative;

			&:before {
				content: '';
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: var(--color-purple);
				position: absolute;
				top: 7px;
				left: 0;
			}

		}
	}

	p, li {

		font-size: 12px !important;
		line-height: 1.375 !important;
		font-weight: 400 !important;
		color: var(--color-dark-neutral) !important;

		@include bp-min(sm) {
			font-size: 14px !important;
		}

	}

}

.colHeadline {

	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	p {
		margin: 0;
		font-size: 8px;
		line-height: 1.5;
		color: var(--color-darker-neutral);

		@include bp-min(sm) {
			font-size: 12px;
		}
	}

}

.colCheck {

	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	svg {
		width: auto;
		height: 25px;
		fill: var(--color-turquoise);
	}

}

.colBtn {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.colBtnInner {

	width: 100%;
	text-align: center;
	margin-top: 10px;

	p {

		@include bp-max(sm) {
			font-size: 8px;
		}

	}

}

.highlightLabel {

	position: absolute;
	top: -24px;
	left: -2px;
	width: calc(100% + 4px);
	height: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: var(--color-turquoise);
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;


	@include bp-min(sm) {
		top: -32px;
		height: 32px;
	}

	p {

		margin: 0;
		text-align: center;
		color: var(--color-white);
		font-weight: 700;
		font-size: 9px;
		line-height: 1.1;

		@include bp-min(sm) {
			font-size: 12px;
			line-height: 1.2;
		}

	}

}

.colWithHighlightLabel {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.popupTitle {

	font-size: 26px;
	font-weight: 300;
	color: var(--color-darker-neutral);
	line-height: 1.333;
	margin: 0;

	@include bp-min(md) {
		font-size: 32px;
		line-height: 1.25;
	}

	@include bp-min(lg) {
		font-size: 40px;
		line-height: 1.2;
	}

}

.popupSubtitle {

	font-size: 18px;
	font-weight: 300;
	color: var(--color-darker-neutral);
	line-height: 1.333;
	margin: 0;

	@include bp-min(md) {
		font-size: 20px;
	}

}

.popupContent {
	font-size: 12px;
	line-height: 1.375;
	font-weight: 400;
	color: var(--color-dark-neutral);

	@include bp-min(xs) {
		font-size: 16px;
	}

	strong {
		color: var(--color-darker-neutral);
	}
}
