@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {
	gap: 0.75rem;
}

.items {

	display: flex;
	flex-direction: column;
	gap: 1rem;

	@include bp-min(md) {
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
	}

	&.hasEqualHeight {
		@include bp-min(md) {
			align-items: stretch;
		}
	}

	&.itemsHasFocus {
		&.noSelection {
			.itemWrapper {
				&:first-child {
					.input {
						& ~ .item {
							box-shadow: 0 1px 8px 0 rgba(68, 68, 68, 0.00), 0 0 0 4px #FFF, 0 0 0 6px var(--color-focus);
						}
					}
				}
			}
		}
	}

}

.itemWrapper {

	position: relative;
	flex-grow: 1;
	flex-basis: 0;

	@include bp-min(md) {
		max-width: 400px;
	}

	&.hasBannerPlaceholder {

		.item {

			@include bp-min(md) {
				height: calc(100% - 25px);
			}

		}

	}

	&:hover {

		.input {

			&:not(:disabled):not(.error) {

				&:not(:checked) {

					& ~ .banner {
						background-color: var(--color-dark-turquoise);
					}

					& ~ .item {

						border-color: var(--color-medium-neutral);

						.radio {
							border-color: var(--color-darker-neutral);
						}

					}

				}

				&:checked {

					& ~ .banner {
						background-color: var(--color-dark-turquoise);
					}

					& ~ .item {

						&:after {
							border-color: var(--color-dark-turquoise);
						}

						.radio {

							border-color: var(--color-dark-turquoise);

							.innerRadio {
								background-color: var(--color-dark-turquoise);
							}

						}

					}

				}

			}

		}

	}

}

.banner {
	position: relative;
	z-index: 2;
	width: calc(100% + 2 * 1.25rem + 2px);
	height: 25px;
	background-color: var(--color-turquoise);
	text-align: center;
	font: var(--typescale-headline-xSmall-font);
	color: var(--color-white);
	font-weight: var(--typescale-strong-weight);
	padding-top: 0.075rem;
	padding-bottom: 0.2rem;
	transform: translate(calc(-1.25rem - 1px), calc(-1rem - 1px));
	border-top-left-radius: var(--border-radius-default);
	border-top-right-radius: var(--border-radius-default);
}

.bannerPlaceholder {

	display: none;
	width: 100%;
	height: 25px;

	@include bp-min(md) {
		display: block;
	}

}

.item {
	position: relative;
	border: 1px solid var(--color-light-neutral);
	border-radius: var(--border-radius-default);
	padding: 1rem 1.25rem;
	cursor: pointer;
	height: 100%;
}

.itemInner {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
}

.input {

	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

	&:checked {

		&.hasFocus {

			& ~ .item {
				box-shadow: 0 1px 8px 0 rgba(68, 68, 68, 0.00), 0 0 0 4px #FFF, 0 0 0 6px var(--color-focus);
			}

		}

		& ~ .item {

			border-color: transparent;
			background-color: var(--color-light-turquoise);

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				height: 100%;
				z-index: 2;
				border: 2px solid var(--color-turquoise);
				border-radius: var(--border-radius-default);
				pointer-events: none;
			}

		}

	}

	&.error {

		&:not(:checked) {

			& ~ .item {
				border-color: var(--color-dark-red);
			}

		}

		& ~ .item {

			.radio {

				border-color: var(--color-dark-red);

				.innerRadio {
					background-color: var(--color-dark-red);
				}

			}

		}

		&:checked {

			& ~ .item {

				background-color: var(--color-light-red);

				&:after {
					border-color: var(--color-dark-red);
				}

			}

		}

	}

	&:disabled {

		& ~ .item {

			.radio {

				background-color: var(--color-lighter-neutral);
				border-color: var(--color-light-neutral);
				cursor: not-allowed;

				.innerRadio {
					background-color: var(--color-light-neutral);
				}

			}

		}

	}

}

.content {
	order: 2;
	flex-grow: 1;
}

.radio {

	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	position: relative;
	border: 1px solid var(--color-medium-neutral);
	background-color: var(--color-white);
	cursor: pointer;
	order: 1;
	flex-shrink: 0;

	.innerRadio {
		display: block;
		width: 0.75rem;
		height: 0.75rem;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		background-color: var(--color-turquoise);
	}

	&.checked {

		border-color: var(--color-turquoise);
		border-width: 2px;

		.innerRadio {
			opacity: 1;
		}
	}

}
