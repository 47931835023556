@import 'src/assets/styles/mixins/_breakpoints.scss';

.applicantBox {
	padding-bottom: 20px;
	height: 100%;
}

.applicantBoxDisabled {
	pointer-events: none;
	opacity: 0.5;
	cursor: not-allowed;
}

@include bp-min(md) {

	.finalStepsBox {
		padding: 15px 25px 0 5px;
	}

	.finalStepsBox:last-child {
		padding-right: 5px;
	}

}
