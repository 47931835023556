@import 'src/assets/styles/mixins/_breakpoints.scss';

.icon {

	width: 100%;
	height: 70px;

	@include bp-min(sm) {
		width: 100px;
		height: 100px;
		margin-right: 20px;
	}

	svg {
		width: 100%;
		height: 100%;
		fill: #9BC3D3;
	}

}
