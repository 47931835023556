@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {
	padding-bottom: 70px;
}

.headerSpacer {

	height: 30px;

	@media (min-width: $sm-max) and (max-height: 699px) {
		height: 50px;
	}

	@media (min-width: $md-min) and (max-height: 699px) {
		height: 50px;
	}

	@media (min-width: $sm-max) and (min-height: 700px) {
		height: 50px;
	}

	@media (min-width: $md-min) and (min-height: 700px) {
		height: 80px;
	}

}

.headerSpacerProgressBar {

	height: 20px;

	@include bp-min(sm) {
		height: 45px;
	}

}

.footerSpacer {
	height: 70px;
}

.background {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}
