:root {
	/*** Colors ***/
	--color-white: #ffffff;

	--color-lighter-neutral: #f2f2f2;
	--color-light-neutral: #cccccc;
	--color-medium-neutral: #8f8f8f;
	--color-dark-neutral: #727272;
	--color-darker-neutral: #222222;

	--color-light-sky: #eff6f9;
	--color-sky: #deedf2;
	--color-medium-sky: #c3dee7;
	--color-dark-sky: #9bc3d3;
	--color-darker-sky: #6895a8;
	--color-darkest-sky: #476277;

	--color-decorative-sky: #23779A;

	--color-light-red: #fee5e5;
	--color-red: #ec0000;
	--color-medium-red: #cc0000;
	--color-dark-red: #990000;

	--color-lighter-purple: #ecd7e1;
	--color-light-purple: #e2c3d2;
	--color-purple: #9e3667;
	--color-dark-purple: #732645;
	--color-darker-purple: #5a1b35;

	--color-light-turquoise: #ecf5f5;
	--color-medium-light-turquoise: #dcecec;
	--color-turquoise: #127277;
	--color-dark-turquoise: #0d5155;
	--color-darker-turquoise: #0a4346;

	--color-light-yellow: #fffaeb;
	--color-yellow: #ffb613;
	--color-dark-yellow: #856300;

	--color-light-green: #f0f8f0;
	--color-green: #008035;

	--color-focus: #3366ff;
	--color-focus-input: var(--color-turquoise);

	/*** Font ***/
	--font-family-headline: 'Santander Headline', monospace;
	--font-family-paragraph: 'Santander MicroText', monospace;

	--typescale-light-weight: 300;
	--typescale-default-weight: 400;
	--typescale-strong-weight: 700;

	// Body
	--typescale-caption-font: 0.75rem/1rem var(--font-family-paragraph);
	--typescale-body-font: 0.875rem/1.25rem var(--font-family-paragraph);
	--typescale-body-large-font: 1rem/1.5rem var(--font-family-paragraph);
	--typescale-body-xlarge-font: 1.125rem/1.5rem var(--font-family-paragraph);

	// Headline
	--typescale-headline-xSmall-font: var(--typescale-strong-weight) 0.75rem/1.25rem var(--font-family-headline);
	--typescale-headline-small-font: var(--typescale-strong-weight) 1.125rem/1.5rem var(--font-family-headline);
	--typescale-headline-medium-font: var(--typescale-strong-weight) 1.25rem/1.5rem var(--font-family-headline);
	--typescale-headline-large-font: var(--typescale-strong-weight) 1.5rem/2rem var(--font-family-headline);
	--typescale-headline-xlarge-font: var(--typescale-strong-weight) 1.75rem/2rem var(--font-family-headline);

	--typescale-headline-mobile-small-font: var(--typescale-strong-weight) 1rem/1.5rem var(--font-family-headline);
	--typescale-headline-mobile-medium-font: var(--typescale-strong-weight) 1.125rem/1.5rem var(--font-family-headline);
	--typescale-headline-mobile-large-font: var(--typescale-strong-weight) 1.25rem/1.5rem var(--font-family-headline);
	--typescale-headline-mobile-xlarge-font: var(--typescale-strong-weight) 1.5rem/2rem var(--font-family-headline);

	// Display
	--typescale-display-small-font: 2.625rem/3.25rem var(--font-family-headline);
	--typescale-display-large-font: 3rem/3.75rem var(--font-family-headline);
	--typescale-display-xlarge-font: 3.375rem/4rem var(--font-family-headline);

	--typescale-display-mobile-small-font: 1.75rem/2rem var(--font-family-headline);
	--typescale-display-mobile-large-font: 2.625rem/3.25rem var(--font-family-headline);
	--typescale-display-mobile-xlarge-font: 3.375rem/4rem var(--font-family-headline);

	/*** Layout ***/
	--transition-default-time: 0.2s;
	--transition-default: all var(--transition-default-time) ease-in-out;

	--border-radius-small: 0.25rem;
	--border-radius-default: 0.5rem;

	--box-shadow-default: 0 1px 10px 0 rgba(68, 68, 68, 0.20);

	--icon-size-m: 1.5rem;
	--icon-size-l: 2rem;
}
