@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

	&.hasImage {

		.title {

			@media (min-width: 400px) {
				padding-right: 150px;
			}

			@include bp-min(md) {
				padding-right: 100px;
			}

		}

		.items {

			padding-right: 100px;

			@media (min-width: 400px) {
				padding-right: 150px;
			}

			@include bp-min(md) {
				padding-right: 100px;
			}

		}

	}

}

.items {

	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	@include bp-min(md) {
		flex-direction: row;
	}

}

.item {

	display: flex;
	flex-direction: row;
	align-items: stretch;

	&:not(:last-child) {

		margin-bottom: 5px;

		@include bp-min(sm) {
			margin-bottom: 0;
			margin-right: 15px;
		}

	}

}

.icon {

	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 5px;

	width: var(--icon-size-m);
	height: var(--icon-size-m);

	svg {
		width: var(--icon-size-m);
		height: var(--icon-size-m);
		fill: var(--color-red);
	}

}

.text {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.image {

	position: absolute;
	bottom: 0;
	right: 0;
	height: 50%;
	aspect-ratio: 1/1;
	padding: 1.5rem 1.75rem;
	z-index: 1;

	@media (min-width: 370px) {
		height: 60%;
	}

	@media (min-width: 420px) {
		height: 70%;
	}

	@media (min-width: 500px) {
		height: 100%;
	}

	svg {
		height: 100%;
		width: auto;
	}

}
