@import 'src/assets/styles/mixins/_breakpoints.scss';

.tooltipRow {
	display: flex;
	flex-direction: row;
	gap: 0.25rem;
}

.closeIcon {

	display: inline-block;
	width: var(--icon-size-m);
	height: var(--icon-size-m);
	cursor: pointer;

	svg {
		cursor: pointer;
		fill: var(--color-purple);
	}

}


.upsellAmount {

	&,
	p {
		font-size: 16px;
		font-weight: 700;
		color: var(--color-darker-neutral);

		@include bp-min(sm) {
			font-size: 18px;
		}
	}

}

.upsellAmountValue {

	font-size: 16px;
	font-weight: 700;
	text-align: right;
	margin-right: 0;
	color: var(--color-turquoise);

	@include bp-min(sm) {
		font-size: 22px;
	}

}

.upsellRuntimeValue {

	font-size: 16px;
	font-weight: 700;
	text-align: right;
	margin-right: 0;

	@include bp-min(sm) {
		font-size: 22px;
	}

}
