@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

}

.logoDesktop,
.logoMobile {
	height: 30px;
	width: auto;
}

.logoDesktop {

	display: none;

	@include bp-min(md) {
		display: block;
	}

}

.logoMobile {

	display: block;

	@include bp-min(md) {
		display: none;
	}

}
