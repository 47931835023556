body {
	background-color: var(--color-white);
	font-family: var(--font-family-paragraph);
	line-height: 1.3333333;
	color: var(--color-darker-neutral);
}

.anchor {
	width: 100%;
	height: 1px;
}

.mt {

	&--0 {
		margin-top: 0;
	}

	&--5 {
		margin-top: 5px;
	}

	&--10 {
		margin-top: 10px;
	}

	&--15 {
		margin-top: 15px;
	}

	&--20 {
		margin-top: 20px;
	}

	&--30 {
		margin-top: 30px;
	}

}

.mb {

	&--0 {
		margin-bottom: 0;
	}

	&--5 {
		margin-bottom: 5px;
	}

	&--10 {
		margin-bottom: 10px;
	}

	&--15 {
		margin-bottom: 15px;
	}

	&--20 {
		margin-bottom: 20px;
	}

	&--30 {
		margin-bottom: 30px;
	}

}
